import React, { type FC } from 'react'
import { Link, type RouteComponentProps } from '@reach/router'
import { t } from 'i18next'

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
} from '@awell-health/design-system'

import { useRetoolConfig } from '../../hooks/useRetoolConfig'
import { isNil } from 'lodash'

export const Worklists: FC<RouteComponentProps> = () => {
  
  const { retoolConfig } = useRetoolConfig()

  // filter careflow specific dashboards
  const apps = retoolConfig.worklists.filter(app => isNil(app.careflowId))

  return (
    <div className='p-6 h-full'>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead key='name' sortable={false}>
              {t('worklists')}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {apps.map(app => (
            <TableRow key={app.id}>
              <TableCell>
                <Link to={app.id} className='text-blue-600'>
                  {app.title}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

Worklists.displayName = 'Worklists'
