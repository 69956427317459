import {
  ComboBox,
  findOption,
  type OptionProps,
} from '@awell/ui-kit/components/ComboBox'
import { DeleteIconButton } from '@awell/ui-kit/components/DeleteIconButton'
import { InputField } from '@awell/ui-kit/components/InputField'
import { isValidInputValue } from '@awell/ui-kit/utils'
import React from 'react'
import { t } from 'i18next'
import { type Control, Controller, type FieldErrors } from 'react-hook-form'
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { Button } from '@awell/ui-kit/components/Button'
import { Plus } from '@awell/ui-kit/components/Icons'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { type IdentifierField } from '../types'
import { useStyles } from './useStyles'
import classnames from 'classnames'

interface IdentifiersProps {
  fields: Array<IdentifierField>
  addFieldTitle: string
  onAppend: (data: IdentifierField) => void
  onRemove: (index: number) => void
  control: Control
  errors: FieldErrors
  identifierSystems: Array<OptionProps>
}

export const Identifiers = ({
  fields,
  control,
  errors,
  addFieldTitle,
  onAppend,
  onRemove,
  identifierSystems,
}: IdentifiersProps): JSX.Element => {
  
  const classes = useStyles({ variant: 'wide' })

  const getInputErrorMessage = (
    errors: Record<string, any>,
    name: string,
  ): string | undefined => {
    if (errors[name]?.type === 'required') {
      return t('form_mandatory_error_text')
    }
    if (errors[name]?.type === 'validate') {
      return t('form_invalid_error_text')
    }
  }

  const isUnusedSystem = (systemOption: OptionProps): boolean =>
    !fields.some(field => field.system === systemOption.value)

  const availableIdentifiers = identifierSystems.filter(isUnusedSystem)

  return (
    <div>
      {fields.map((field, index) => {
        return (
          <div className={classes.inputFieldsContainer} key={field.id}>
            <Controller
              name={`identifier[${index}].system`}
              control={control}
              render={({ onChange, value }, { invalid }) => {
                const selectedOption = findOption(
                  identifierSystems,
                  value as string,
                )
                return (
                  <ComboBox
                    options={availableIdentifiers}
                    // @ts-expect-error ComboBox onChange typing needs fixing
                    onChange={(option: OptionProps) => onChange(option.value)}
                    selectedOption={selectedOption}
                    id={`identifier[${index}].system`}
                    className={classnames(classes.inputKey)}
                    compact
                    fullWidth
                    error={invalid}
                    inputStyle={{ paddingTop: '3px', paddingBottom: '3px' }}
                  />
                )
              }}
            />
            <Controller
              name={`identifier[${index}].value`}
              control={control}
              defaultValue={field.value}
              rules={{
                validate: value =>
                  isValidInputValue({
                    inputRequired: true,
                    inputType: 'text',
                    inputValue: value,
                  }),
              }}
              render={({ onChange, onBlur, value }) => {
                return (
                  <InputField
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder='Identifier'
                    value={value}
                    className={classnames(classes.inputValue)}
                    compact
                    error={getInputErrorMessage(
                      errors,
                      `identifier[${index}].value`,
                    )}
                    withErrorSpace
                    required
                  />
                )
              }}
            />
            <div className={classes.deleteBtnContainer}>
              <DeleteIconButton
                onDelete={() => {
                  onRemove(index)
                }}
                tooltip=''
                labels={{
                  heading: t('remove_identifier_modal_header', {
                    item: t('identifier'),
                  }),
                  primaryAction: t('delete'),
                  secondaryAction: t('cancel'),
                }}
              >
                <Type variant={TypeVariants.regular}>
                  {t('remove_identifier_modal_description')}
                </Type>
              </DeleteIconButton>
            </div>
          </div>
        )
      })}

      <Button
        size='small'
        variant='text'
        onClick={() => onAppend({ system: '', value: '' })}
        startIcon={
          /* eslint-disable react/jsx-wrap-multilines */
          <IconWrapper size='xxs' color={colors.brand100}>
            <Plus />
          </IconWrapper>
        }
      >
        {addFieldTitle}
      </Button>
    </div>
  )
}
