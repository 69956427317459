import { type Maybe } from '@awell/libs-web/types'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { Step, StepConnector, StepLabel, Stepper } from '@material-ui/core'
import { type RouteComponentProps } from '@reach/router'
import React, { useEffect, useState, type FC, type ReactElement } from 'react'
import { t } from 'i18next'
import { usePatientDemographics } from '../../../hooks/usePatientDemographics'
import { type PatientProfile } from '../types'
import { useStyles } from '../useStyles'
import { IdentificationStep } from './Steps'
import { ReviewAndCreatePatientStep } from './Steps/ReviewAndCreatePatientStep'
import { ImportingStep } from './Steps/ImportingStep'
import { usePatientDemographicsConfiguration } from '../../../hooks/usePatientDemographicConfiguration'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { WarningRound } from '@awell/ui-kit/components/Icons'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'

export const RequestPatientDemographics: FC<
  RouteComponentProps
> = (): ReactElement => {
  const { addBreadcrumbs } = useBreadcrumbs()
  const [activeStep, setActiveStep] = useState(0)
  const [importLoading, setImportLoading] = useState(false)
  const [patientIdentifier, setPatientIdentifier] = useState<string>()
  const [queryId, setQueryId] = useState<string>()
  const [isImportSuccess, setIsImportSuccess] = useState<boolean>(false)

  const [importedPatients, setImportedPatients] = useState<
    Array<Maybe<PatientProfile>> | undefined
  >([])
  const [totalImported, setTotalImported] = useState<number>()
  const classes = useStyles()
  
  const { requestPatientDemographics } = usePatientDemographics()
  const {
    loading: isConfigurationLoading,
    is_enabled: isImportingEnabled,
    input_text_label,
  } = usePatientDemographicsConfiguration()

  const breadcrumb = [
    {
      label: t('create_patient'),
      url: '/patients/create',
      level: 1,
    },
    {
      label: t('import_patient'),
      url: '/patients/import',
      level: 2,
      description:
        'Retrieve basic demographic information about a patient from your system',
    },
  ]

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])

  const importPatient = async (patient_identifier: string): Promise<void> => {
    setImportedPatients(undefined)
    setTotalImported(undefined)
    try {
      setImportLoading(true)
      const response = await requestPatientDemographics(patient_identifier)
      setImportedPatients(response?.entry)
      setTotalImported(response?.total)
      setQueryId(response?.query_id)
      setIsImportSuccess(response?.success ?? false)
    } catch (error) {}
    setImportLoading(false)
  }

  const handleSubmitIdentification = (patient_identifier: string): void => {
    setPatientIdentifier(patient_identifier)
    setActiveStep(1)
    void importPatient(patient_identifier)
  }

  const handleStepBack = (): void => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  const handleStepForward = (): void => {
    if (activeStep < 2) {
      setActiveStep(activeStep + 1)
    }
  }
  const handleGoToFirstStep = (): void => {
    setPatientIdentifier(undefined)
    setImportedPatients(undefined)
    setTotalImported(undefined)
    setActiveStep(0)
  }

  const steps = [t('identification'), t('importing'), t('create_patient')]

  if (isConfigurationLoading) {
    return (
      <div className={classes.importConfigurationLoadingContainer}>
        <Spinner />
        <Type variant={TypeVariants.regular} color={colors.neutralMid200}>
          {t('import_patient_configuration_loading')}
        </Type>
      </div>
    )
  }

  if (!isConfigurationLoading && !isImportingEnabled) {
    return (
      <div className={classes.importConfigurationLoadingContainer}>
        <IconWrapper
          className={classes.importConfigurationLoadingWarningIcon}
          size='l'
        >
          <WarningRound />
        </IconWrapper>
        <Type variant={TypeVariants.regular} color={colors.neutralMid200}>
          {t('import_patient_configuration_missing')}
        </Type>
      </div>
    )
  }

  return (
    <div>
      <Stepper activeStep={activeStep} connector={<StepConnector />}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {
            completed: index < activeStep,
          }
          const labelProps: {
            optional?: React.ReactNode
            error?: boolean
          } = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      <div className={classes.stepContentContainer}>
        {activeStep === 0 && (
          <IdentificationStep
            onSubmitIdentification={handleSubmitIdentification}
            inputTextLabel={input_text_label}
          />
        )}

        {activeStep === 1 && (
          <ImportingStep
            loading={importLoading}
            patientProfiles={importedPatients}
            total={totalImported}
            queryId={queryId}
            isImportSuccess={isImportSuccess}
            onGoBack={handleStepBack}
            onSuccessfulImport={handleStepForward}
          />
        )}

        {activeStep === 2 && (
          <ReviewAndCreatePatientStep
            patientProfiles={importedPatients ?? []}
            patientIdentifier={patientIdentifier ?? ''}
            onClickGoToFirstStep={handleGoToFirstStep}
            queryId={queryId!}
          />
        )}
      </div>
    </div>
  )
}
