import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import { capitalize, last } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { spacing } from '../../utils/style-guide'
import { ActivityStatus } from '../AwellActivity'
import { Text } from '../Typography'
import { type Activity } from '../../compositions/ActivityFeed'

const useStyles = makeStyles({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing.xs,
  },
})

interface DetailsTabContentProps {
  activity: Activity
}

export const DetailsTabContent = ({
  activity,
}: DetailsTabContentProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    // @ts-expect-error TODO fix type issue
    track: { title: trackTitle },
    container_name: stepName,
    date: activityStartDate,
    sub_activities,
  } = activity

  const formatDate = (date: string) => format(new Date(date), 'dd MMM yyyy, h:mm:ss a')

  const activityCompletedDate = last(sub_activities)?.date ?? activity.date

  return (
    <div className={classes.detailsContainer}>
      <div>
        <Text variant='textMedium'>{capitalize(t('track'))}:</Text>
        <Text variant='textRegular'>{trackTitle}</Text>
      </div>
      <div>
        <Text variant='textMedium'>{capitalize(t('step'))}:</Text>
        <Text variant='textRegular'>{stepName}</Text>
      </div>
      <div>
        <Text variant='textMedium'>{capitalize(t('activated_on'))}:</Text>
        <Text variant='textRegular'>{formatDate(activityStartDate)}</Text>
      </div>
      <div>
        <Text variant='textMedium'>{capitalize(t('completed_on'))}:</Text>
        <Text variant='textRegular'>
          {activity.status === ActivityStatus.Done
            ? formatDate(activityCompletedDate)
            : t('not_completed')}
        </Text>
      </div>
    </div>
  )
}

DetailsTabContent.displayName = 'DetailsTabContent'
