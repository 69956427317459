import { useApolloClient } from '@apollo/client'
import { updateQuery } from '@awell/libs-web/graphql'
import { isNil } from 'lodash'
import { useEffect } from 'react'
import { t } from 'i18next'
import { ElementStatus } from '../../generated/types-orchestration'
import { useNotifications } from '../useNotifications'
import {
  ElementType,
  GetScheduledStepsDocument,
  type GetScheduledStepsQuery,
  type ScheduledStep,
  useGetScheduledStepsQuery,
  useOnElementCompletedSubscription,
  useOnElementCreatedSubscription,
  useOnElementUpdatedSubscription,
} from './types'

interface UseScheduledStepsHook {
  loading: boolean
  scheduledSteps?: Array<ScheduledStep>
}

export const useScheduledSteps = (pathwayId: string): UseScheduledStepsHook => {
  const { notifyError } = useNotifications()
  const client = useApolloClient()
  
  const variables = {
    pathway_id: pathwayId,
    element_type: ElementType.Step,
  }
  const { data, loading, error } = useGetScheduledStepsQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  })

  const onStepCreated = useOnElementCreatedSubscription({ variables })
  const onStepUpdated = useOnElementUpdatedSubscription({ variables })
  const onStepCompleted = useOnElementCompletedSubscription({ variables })

  const scheduledSteps = data?.scheduledSteps.steps ?? []

  const updateQueryResults = (stepElements: Array<ScheduledStep>): void => {
    if (!data) return
    const updatedQuery = updateQuery<
      GetScheduledStepsQuery,
      Array<ScheduledStep>
    >(data, ['scheduledSteps', 'steps'], stepElements)
    updatedQuery.scheduledSteps.success = true
    client.writeQuery({
      query: GetScheduledStepsDocument,
      variables,
      data: updatedQuery,
    })
  }

  useEffect(() => {
    if (!isNil(onStepCreated.data)) {
      const {
        data: { elementCreated },
      } = onStepCreated
      if (elementCreated.status !== ElementStatus.Scheduled) {
        return
      }

      const updatedElements = [
        elementCreated,
        ...scheduledSteps.filter(element => element.id !== elementCreated.id),
      ]
      updateQueryResults(updatedElements)
    }
  }, [onStepCreated.data])

  useEffect(() => {
    if (!isNil(onStepUpdated.data)) {
      const {
        data: { elementUpdated },
      } = onStepUpdated
      const updatedElements = [
        elementUpdated,
        ...scheduledSteps.filter(element => element.id !== elementUpdated.id),
      ].filter(element => element.status === ElementStatus.Scheduled)
      updateQueryResults(updatedElements)
    }
  }, [onStepUpdated.data])

  useEffect(() => {
    if (!isNil(onStepCompleted.data)) {
      const {
        data: { elementCompleted },
      } = onStepCompleted
      const updatedElements = [
        elementCompleted,
        ...scheduledSteps.filter(element => element.id !== elementCompleted.id),
      ].filter(element => element.status === ElementStatus.Scheduled)
      updateQueryResults(updatedElements)
    }
  }, [onStepCompleted.data])

  if (error) {
    notifyError({
      message: t('error_loading_scheduled_steps'),
      error,
    })
  }

  return { loading, scheduledSteps }
}
