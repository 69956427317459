import { t } from 'i18next'
import {
  useGetPathwayQuery,
  type Pathway,
  useOnPathwayUpdatedSubscription,
} from './types'
import { useNotifications } from '../useNotifications'
import { isNil } from 'lodash'

interface UsePathwayHook {
  loading: boolean
  error: boolean
  pathway?: Pathway
}

export const usePathway = (pathway_id: string | undefined): UsePathwayHook => {
  const { notifyError } = useNotifications()
  
  if (isNil(pathway_id)) {
    return { loading: true, error: false }
  }
  const { data, loading, error } = useGetPathwayQuery({
    // Activity object is being cached and the sub activities object is also being cached.
    // Since both have the same id, sometimes that creates inconsistencies on the activity.
    // This fetch policy is a workaround to avoid this issue.
    fetchPolicy: 'no-cache',
    variables: {
      pathway_id,
    },
  })

  const { data: updatedPathway } = useOnPathwayUpdatedSubscription({
    variables: {
      pathway_id,
    },
  })

  if (error) {
    notifyError({
      message: t('pathway_fetch_error'),
      error,
    })
    return { loading: false, error: true }
  }
  if (loading) {
    return { loading: true, error: false }
  }

  const shouldUseSubscriptionPathway = (): boolean => {
    return updatedPathway?.pathwayUpdated.id === pathway_id
  }

  const queryPathway = data?.pathway.pathway
  const subscriptionPathway = updatedPathway?.pathwayUpdated

  return {
    error: false,
    loading: false,
    pathway: shouldUseSubscriptionPathway()
      ? subscriptionPathway
      : queryPathway,
  }
}
