import { t } from 'i18next'
import { type Maybe } from '@awell/libs-web/types'
import { useNotifications } from '../useNotifications'
import {
  type CreatePatientInput,
  type Patient,
  useCreatePatientMutation,
} from './types'
import { isEmpty, isNil } from 'lodash'

interface UseCreatePatientHook {
  createPatient: (profile: CreatePatientInput) => Promise<Maybe<Patient>>
}

export const useCreatePatient = (): UseCreatePatientHook => {
  const { notifyError } = useNotifications()
  

  const [createPatientMutation] = useCreatePatientMutation()

  const createPatient = async (
    profile: CreatePatientInput,
  ): Promise<Maybe<Patient>> => {
    const nullIfEmpty = (text: Maybe<string>): string | undefined =>
      isNil(text) || isEmpty(text) ? undefined : text

    try {
      const { data } = await createPatientMutation({
        variables: {
          input: {
            ...profile,
            email: nullIfEmpty(profile.email),
            birth_date: nullIfEmpty(profile.birth_date),
          },
        },
        update: (cache, { data: patientData }) => {
          const patient = patientData?.createPatient.patient
          if (patient) {
            cache.modify({
              fields: {
                patients(existingData = { patients: [] }) {
                  return [...existingData.patients, patient]
                },
              },
            })
          }
        },
      })
      return data?.createPatient.patient
    } catch (error) {
      notifyError({
        message: t('create_patient_error'),
        error,
      })
    }
    return undefined
  }
  return {
    createPatient,
  }
}
