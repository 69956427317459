import React, { type FC, type RefObject, useEffect } from 'react'
import { useIntersection } from 'react-use'
import { t } from 'i18next'

import { Spinner } from '@awell-health/design-system'

import { FeedItem } from './FeedItems/FeedItem'
import { ScheduledElementsCard } from './FeedItems/ScheduledElementsCard'

import { type BaselineDataPoint } from '@awell/ui-kit/components/AwellActivity'
import { DateGroup } from './FeedItems/DateGroup'
import { type ScheduledElement, type Activity } from '../types'
import { getDate } from './helperFunctions/getDate'

export interface Props {
  activities: Array<Activity>
  selectedActivityId?: string
  onSelectActivity: (activity: Activity) => void
  baselineDataPoints?: Array<BaselineDataPoint>
  scheduledElements?: Array<ScheduledElement>
  pathwayStatusExplanation?: string
  onLoadMore: () => Promise<void>
  hasMore: boolean
  loading: boolean
}

export const Feed: FC<Props> = ({
  activities,
  selectedActivityId,
  onSelectActivity,
  baselineDataPoints,
  scheduledElements = [],
  pathwayStatusExplanation,
  onLoadMore,
  hasMore,
  loading,
}) => {
  

  const intersectionRef: RefObject<HTMLDivElement> =
    React.useRef<HTMLDivElement>(null)

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.2, // 0.2 to trigger at 80% scroll
  })

  useEffect(() => {
    if (intersection?.isIntersecting === true && !loading) {
      void onLoadMore()
    }
  }, [intersection?.isIntersecting])

  const timelineClass = 'absolute left-12 bottom-[-56px] w-0.5 h-14 bg-gray-200'
  return (
    <div>
      <div className='flex flex-col items-center gap-14 overflow-y-auto pt-5'>
        {scheduledElements.length > 0 && (
          <ScheduledElementsCard scheduledElements={scheduledElements} />
        )}

        {scheduledElements.length === 0 && activities.length > 0 && (
          <div className='w-[650px] relative'>
            <DateGroup date={getDate(activities[0].date)} />
            <div className={timelineClass} />
          </div>
        )}
        {activities.map((activity, index) => (
          <div key={`${activity.id}-${index}`} className='relative'>
            {index < activities.length - 1 && <div className={timelineClass} />}
            <div className='w-[650px]'>
              <FeedItem
                key={`${activity.id}-${index}`}
                activity={activity}
                selected={activity.id === selectedActivityId}
                onSelect={() => {
                  onSelectActivity(activity)
                }}
                baselineDataPoints={baselineDataPoints}
                pathwayStatusExplanation={pathwayStatusExplanation}
              />
            </div>
          </div>
        ))}
        {loading && (
          <div className='flex justify-center'>
            <Spinner variant='infinity' size='lg' />
          </div>
        )}

        {!loading && hasMore && (
          <div ref={intersectionRef} className='p-2'></div>
        )}

        {!loading && !hasMore && (
          <p className='text-center py-4 text-md text-gray-500'>
            {activities.length > 0 && (
              <b>{t('activityfeed:no_activities_to_load')}</b>
            )}
            {activities.length === 0 && (
              <b>{t('activityfeed:no_activities_found')}</b>
            )}
          </p>
        )}
      </div>
    </div>
  )
}

Feed.displayName = 'Feed'
