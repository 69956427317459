import translation from './en/translation'
import activityfeed from './en/activityfeed'
import workinglist from './en/workinglist'

export const defaultNS = 'translation'

export const resources = {
  en: {
    translation,
    activityfeed,
    workinglist,
  },
}
