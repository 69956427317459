import React, { type FC } from 'react'
import Retool from 'react-retool'
import { isNil } from 'lodash'
import { useStytchMember } from '@stytch/react/b2b'
import { Spinner } from '@awell-health/design-system'

import { useRetool } from '../../hooks/useRetool'
import { useNotifications } from '../../hooks/useNotifications'
import { useRetoolConfig } from '../../hooks/useRetoolConfig'

interface CareFlowDashboardProps {
  careflowId: string
  careflowDefinitionId: string
}

export const CareFlowDashboard: FC<CareFlowDashboardProps> = ({
  careflowId,
  careflowDefinitionId,
}) => {
  const { member } = useStytchMember()
  const { retoolConfig } = useRetoolConfig()
  const { notifyError } = useNotifications()

  // allow for passing version param to override the release version from stytch
  const pathname = new URL(window.location.href)
  const params = new URLSearchParams(pathname.search)
  const releaseVersionInQueryParams = params.get('version')

  if (isNil(member)) {
    return <Spinner />
  }

  const groupIds: Array<string> = retoolConfig.permission_groups

  const app = retoolConfig.worklists.find(
    app => app.careflowId === careflowDefinitionId,
  )

  if (isNil(app)) {
    notifyError({
      message:
        'Dashboard is not configured. Please reach out to customer support for assistance.',
    })
  }

  /**
   * Query params have priority over the release version set in Stytch.
   * This easily allows us to override the release version for testing purposes.
   */
  const releaseVersionToLoad = !isNil(releaseVersionInQueryParams)
    ? releaseVersionInQueryParams
    : app?.releaseVersion // Release version set in Stytch

  const request = {
    user: {
      firstName: member.name,
      lastName: '',
      email: member.email_address,
      id: member.member_id,
    },
    groupIds,
    landingPageUuid: app?.id ?? '',
    releaseVersion: releaseVersionToLoad,
  }

  const { url, loading, error } = useRetool(request)

  if (loading) {
    return <Spinner />
  }

  if (!isNil(error)) {
    notifyError({
      message: 'Unable to fetch care flow dashboard',
      error,
    })
  }

  const data = {
    careflowId,
    /**
     * Retool has a bug where the release version is not passed into the Retool app.
     * Attempting to access it via {{ retoolContext.pageTag }} returns undefined.
     *
     * Although the Retool app loads with the correct version (with the request above), the release version is
     * not made available within the app itself.
     *
     * Passing it again via the data prop allows us to display the version number in the app's footer.
     * This helps us easily identify which version a user is currently using.
     */
    releaseVersion: releaseVersionToLoad,
  }

  return (
    <div className='h-full'>
      <Retool url={url} data={data} />
    </div>
  )
}

CareFlowDashboard.displayName = 'CareFlowDashboard'
