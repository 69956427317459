import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources, defaultNS } from '../translations/config'

void i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: ['en', 'nl'],
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    skipOnVariables: false
  },
  defaultNS,
  resources,
})

export default i18n
