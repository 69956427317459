import classnames from 'classnames'
import React, { type FC } from 'react'

import { type Activity, ActivityStatus } from '../../types'
import { useActivityContent } from '@awell/ui-kit/src/compositions/ActivityFeed/useActivityContent'

import { Footer, ActivityLabel, ActivityBody } from './ActivityCard'
import { isEmpty, isNil } from 'lodash'

interface Props {
  onSelect?: () => void
  selected?: boolean
  activity: Activity
}

export const ActivityFeedCard: FC<Props> = ({
  onSelect = () => null,
  selected,
  activity,
}) => {
  const completed =
    activity.status === ActivityStatus.Done ||
    activity.status === ActivityStatus.Expired

  const { content, events } = useActivityContent(activity)

  const stepLabelColor = activity.label?.color ?? 'stepDefault'
  const stepLabelName = activity.label?.text

  // name will have patient name, but if anonymous we fallback to Anonymous
  const getStakeholderName = (): string => {
    if (
      !isNil(activity.indirect_object?.name) &&
      !isEmpty(activity.indirect_object?.name)
    ) {
      return activity.indirect_object.name
    }
    return 'Anonymous'
  }

  return (
    <div
      className={classnames(
        'shadow-sm rounded-md p-6 bg-white border-2 border-neutral-200',
        {
          'border-blue-700': selected,
        },
      )}
    >
      <ActivityLabel
        stepLabelColor={stepLabelColor}
        stepLabelName={stepLabelName}
      />

      <div className='flex flex-col'>
        <ActivityBody
          activity={activity}
          content={content}
          onSelect={onSelect}
          completed={completed}
        />
        <Footer
          content={content}
          events={events}
          date={activity.date}
          stakeholder={getStakeholderName()}
          activityType={activity.object.type}
        />
      </div>
    </div>
  )
}

ActivityFeedCard.displayName = 'ActivityFeedCard'
