import { t } from 'i18next'
import { isNil } from 'lodash'
import { useNotifications } from '../useNotifications'
import { useGetGeneratedClinicalNoteQuery } from './types'
import type { GeneratedClinicalNote } from './types'

interface UseGeneratedClinicalNoteHook {
  loading: boolean
  clinicalNote?: GeneratedClinicalNote
}

export const useGeneratedClinicalNote = (
  clinicalNoteId: string,
): UseGeneratedClinicalNoteHook => {
  if (isNil(clinicalNoteId)) {
    return { loading: true }
  }
  
  const { notifyError } = useNotifications()

  const {
    data,
    loading,
    error: messageError,
  } = useGetGeneratedClinicalNoteQuery({
    variables: { id: clinicalNoteId },
  })

  notifyError({
    message: t('error_load_message'),
    error: messageError,
  })

  return {
    loading,
    clinicalNote: data?.clinicalNote.clinical_note,
  }
}
