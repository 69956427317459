import { type Maybe } from '@awell/ui-kit/utils'
import { t } from 'i18next'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { useNotifications } from '../useNotifications'
import {
  type Patient,
  type PatientProfileInput,
  useUpdatePatientMutation,
} from './types'

interface UseUpdatePatientHook {
  updatePatient: (profile: PatientProfileInput) => Promise<Maybe<Patient>>
}

export const useUpdatePatient = (patient_id: string): UseUpdatePatientHook => {
  const { notifyError, notify } = useNotifications()
  

  const [updatePatientMutation] = useUpdatePatientMutation()

  const updatePatient = async (profile: PatientProfileInput) => {
    try {
      const { data } = await updatePatientMutation({
        variables: {
          input: {
            patient_id,
            profile,
          },
        },
      })
      notify({
        message: t('update_patient_success'),
        notifyType: NotificationTypes.success,
      })
      return data?.updatePatient.patient
    } catch (error) {
      notifyError({
        message: t('update_patient_error'),
        error,
      })
    }
    return undefined
  }

  return {
    updatePatient,
  }
}
