import { isNil } from 'lodash'
import React, { type FC } from 'react'
import { BaselineDataPoints } from '@awell/ui-kit/src/components/AwellActivity/BaselineDataPoints'
import { type BaselineDataPoint } from '@awell/ui-kit/src/components/AwellActivity/types'
import { formatDate } from '@awell/ui-kit/utils'

import { Icon } from '@awell-health/design-system'
import ShellyAvatar from '../../../../components/ShellyAvatar'
import {
  type Activity,
  ActivityAction,
  ActivityObjectType,
} from '../../types'
import { ActivitySummary } from './ActivityCard/getActivitySummary'

interface Props {
  activity: Activity
  baselineDataPoints?: Array<BaselineDataPoint>
  pathwayStatusExplanation?: string
}

export const AwellActivityCard: FC<Props> = ({
  activity,
  baselineDataPoints = [],
  pathwayStatusExplanation,
}) => {
  const { action, object, date } = activity

  const getActivityIcon = (
    objectType: ActivityObjectType,
    action: ActivityAction,
  ): React.ReactNode => {
    if (objectType === ActivityObjectType.Reminder) {
      return <Icon icon='RiNotification2Line' size={24} />
    }
    if (action === ActivityAction.Stopped) {
      return <Icon icon='RiSquareFill' size={24} />
    }
    if (action === ActivityAction.Activate) {
      return <Icon icon='RiPlayFill' size={24} />
    }
    return <ShellyAvatar />
  }

  const BaselineDataPointsComponent: FC<{
    objectType: ActivityObjectType
    action: ActivityAction
    baselineDataPoints: Array<BaselineDataPoint>
  }> = ({ objectType, action, baselineDataPoints }) => {
    if (
      action === ActivityAction.Activate &&
      baselineDataPoints.length > 0 &&
      objectType === ActivityObjectType.Pathway
    ) {
      return <BaselineDataPoints baselineDataPoints={baselineDataPoints} />
    }
    return <></>
  }

  const stamp = formatDate({
    isoDateStr: date,
    dateFormat: 'dd MMM yyyy, h:mm:ss a', // 10 Jan 2025, 5:53:00 PM
  })

  return (
    <div className='shadow-sm rounded-md p-6 bg-white border border-neutral-200'>
      <div className='flex gap-3 items-center'>
        <div className='bg-slate-100 border border-slate-200 p-1 rounded-lg'>
          {getActivityIcon(object.type, action)}
        </div>
        <div className='flex justify-between items-center w-full'>
          <ActivitySummary activity={activity} careflowStopExplanation={pathwayStatusExplanation} />
        </div>
      </div>
      <div className='py-2 flex justify-between'>
        <BaselineDataPointsComponent
          objectType={object.type}
          action={action}
          baselineDataPoints={baselineDataPoints}
        />
      </div>
      {!isNil(date) && (
        <div className='flex gap-1 justify-end'>
          <Icon icon='RiTimeFill' size={16} className='fill-slate-600' />
          <div className='text-neutral-500 text-sm'>{stamp}</div>
        </div>
      )}
    </div>
  )
}

AwellActivityCard.displayName = 'AwellActivityCard'
