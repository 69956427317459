import { t } from 'i18next'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { useNotifications } from '../useNotifications'
import { useRetryAllFailedApiCallsMutation } from './types'

interface useRetryAllFailedApiCallsHook {
  retryAllFailedApiCalls: (id: string) => Promise<void>
}

export const useRetryAllFailedApiCalls = (): useRetryAllFailedApiCallsHook => {
  const { notifyError, notify } = useNotifications()
  
  const [retryAllFailedApiCallsMutation] = useRetryAllFailedApiCallsMutation()

  const retryAllFailedApiCalls = async (pathwayId: string) => {
    try {
      await retryAllFailedApiCallsMutation({
        variables: {
          input: {
            pathway_id: pathwayId,
          },
        },
      })
      notify({
        message: t('api_call_retry_success'),
        notifyType: NotificationTypes.success,
      })
    } catch (error) {
      notifyError({
        message: t('api_call_retry_error'),
        error,
      })
    }
  }

  return { retryAllFailedApiCalls }
}
