import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { t } from 'i18next'
import { useState } from 'react'
import { useNotifications } from '../useNotifications'
import type { Activity, Answer, AnswerInput } from './types'
import { useGetFormResponseQuery, useSubmitFormResponseMutation } from './types'

interface UseFormActivityHook {
  loading: boolean
  disabled: boolean
  onSubmit: (response: Array<AnswerInput>) => Promise<void>
  answers: Array<Answer>
}

export const useFormActivity = ({
  activity,
}: {
  activity: Activity
}): UseFormActivityHook => {
  const { id: activity_id, stream_id: pathway_id } = activity

  
  const { notify, notifyError } = useNotifications()
  const [submitFormResponse] = useSubmitFormResponseMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    data: responseData,
    loading: responseLoading,
    error: responseError,
  } = useGetFormResponseQuery({
    variables: {
      pathway_id,
      activity_id,
    },
  })

  if (responseError) {
    notifyError({
      error: responseError,
      message: t('get_form_response_error_notification'),
    })
  }

  const onSubmit = async (response: Array<AnswerInput>) => {
    setIsSubmitting(true)
    try {
      await submitFormResponse({
        variables: {
          input: {
            activity_id,
            response,
          },
        },
        refetchQueries: ['GetFormResponse'],
        awaitRefetchQueries: true,
      })
      notify({
        message: t('form_success_notification'),
        notifyType: NotificationTypes.success,
      })
    } catch (error) {
      setIsSubmitting(false)
      notifyError({
        error,
        message: t('form_submit_error_notification'),
      })
    }
  }

  const answers = responseData?.formResponse.response.answers ?? []

  return {
    loading: responseLoading,
    onSubmit,
    answers,
    disabled: isSubmitting,
  }
}
