import {
  useGetCareflowActivityTypesQuery,
} from './types'


interface UseCareflowActivityTypesHook {
  loading: boolean
  activityTypes: Array<string>
}

export const useCareflowActivityTypes = (
  careflow_id: string,
): UseCareflowActivityTypesHook => {
  const {
    data,
    loading,
  } = useGetCareflowActivityTypesQuery({
    variables: { careflow_id },
    fetchPolicy: 'network-only'
  })

  if (loading) {
    return { loading: true, activityTypes: [] }
  } else {
    return {
      loading: false,
      activityTypes: data?.careflowActivityTypes.activityTypes ?? [],
    }
  }
}
