import { t } from 'i18next'
import { type ApolloQueryResult } from '@apollo/client'
import { isNil } from 'lodash'

import { useNotifications } from '../useNotifications'

import {
  useOnActivityCompletedSubscription,
  useOnActivityCreatedSubscription,
  useOnActivityExpiredSubscription,
  useOnActivityUpdatedSubscription,
} from '../useActivityFeed/types'
import {
  useGetCareflowActivitiesQuery,
  type StakeholderActivityObject,
  type Activity,
  type GetCareflowActivitiesQuery,
  type GetCareflowActivitiesQueryVariables,
} from './types'

interface UseCareflowActivitiesHook {
  loading: boolean
  pagination?: GetCareflowActivitiesQuery['careflowActivities']['pagination']
  sorting?: GetCareflowActivitiesQuery['careflowActivities']['sorting']
  activities?: Array<Activity>
  newActivity?: Activity
  activityCompleted?: Activity
  activityUpdated?: Activity
  activityExpired?: Activity
  stakeholders?: Array<StakeholderActivityObject>
  refetchActivities: (
    query?: Partial<GetCareflowActivitiesQueryVariables>,
  ) => Promise<ApolloQueryResult<GetCareflowActivitiesQuery>>
}

export const useCareflowActivities = (
  careflowId: string,
  filters?: GetCareflowActivitiesQueryVariables['filters'],
  pagination?: GetCareflowActivitiesQueryVariables['pagination'],
  sorting?: GetCareflowActivitiesQueryVariables['sorting'],
): UseCareflowActivitiesHook => {
  const { notifyError } = useNotifications()
  
  
  const baseInputs: GetCareflowActivitiesQueryVariables = {
    pathway_id: careflowId,
    pagination: pagination ?? {
      count: 100,
      offset: 0,
    },
    sorting: sorting ?? {
      field: 'date',
      direction: 'desc',
    },
  }

  const {
    data,
    loading,
    error,
    refetch: refetchActivities,
  } = useGetCareflowActivitiesQuery({
    variables: isNil(filters) ? { ...baseInputs } : { ...baseInputs, filters },
    // the loading state is not working as expected, so we need to fetch the data from the network in the background
    // more details in https://github.com/apollographql/apollo-client/issues/4165
    fetchPolicy: 'cache-and-network',
  })

  const variables = { pathway_id: careflowId }

  const { data: onActivityCreated } = useOnActivityCreatedSubscription({ variables })
  const { data: onActivityUpdated } = useOnActivityUpdatedSubscription({ variables })
  const { data: onActivityCompleted } = useOnActivityCompletedSubscription({ variables })
  const { data: onActivityExpired } = useOnActivityExpiredSubscription({ variables })

  const activities = data?.careflowActivities.activities ?? []
  const newActivity = onActivityCreated?.activityCreated
  const activityCompleted = onActivityCompleted?.activityCompleted
  const activityUpdated = onActivityUpdated?.activityUpdated
  const activityExpired = onActivityExpired?.activityExpired

  if (!isNil(data)) {
    return {
      loading,
      pagination: data.careflowActivities.pagination,
      sorting: data.careflowActivities.sorting,
      activities,
      newActivity,
      activityCompleted,
      activityUpdated,
      activityExpired,
      stakeholders: data.careflowActivities.metadata?.stakeholders ?? [],
      refetchActivities,
    }
  }

  if (!isNil(error)) {
    notifyError({
      message: t('error_loading_activities'),
      error,
    })
    return { loading: false, refetchActivities }
  }

  return { 
    loading, 
    activities,
    activityCompleted,
    activityUpdated,
    activityExpired,
    refetchActivities 
  }
}
