import React from 'react'
import { formatDate } from '../../utils'
import { colors, spacing } from '../../utils/style-guide'
import { Text } from '../Typography'
import classnames from 'classnames'
import { useStyles } from './useStyles'
import { Timing } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { isNil } from 'lodash'

interface TimestampProps {
  timestamp: string
  className?: string
}

export const Timestamp = ({
  timestamp,
  className,
}: TimestampProps): JSX.Element => {
  const classes = useStyles()
  if (isNil(timestamp)) return <></>

  const stamp = formatDate({
    isoDateStr: timestamp,
    dateFormat: 'dd MMM yyyy, h:mm:ss a', // 10 Jan 2025, 5:53:00 PM
  })

  return (
    <span className={classnames([className, classes.timestampContainer])}>
      <IconWrapper
        size='xs'
        color={colors.neutralDark500}
        style={{ marginRight: spacing.xxxs }}
      >
        <Timing />
      </IconWrapper>
      <Text variant='textSmall' color={colors.neutralMid300}>
        {stamp}
      </Text>
    </span>
  )
}
