import { type Column } from '@awell/ui-kit/components/Table'
import React from 'react'
import { capitalize, isEmpty } from 'lodash'
import { t } from 'i18next'
import { LinkCell } from '@awell/ui-kit/components/Table/LinkCell'
import { Button } from '@awell/ui-kit/components/Button'
import { RequestStatus } from '@awell/ui-kit/components/RequestStatus'
import { Text } from '@awell/ui-kit/components/Typography'
import { format } from 'date-fns'
import { type ApiCall } from '../../../generated/types-orchestration'
import { useRetryApiCall } from '../../../hooks/useRetryApiCall'

export const useApiCallsListColumns = (): {
  columns: Array<Column<ApiCall>>
} => {
  
  const { retryApiCall } = useRetryApiCall()
  const renderActionRow = ({ id }: ApiCall) => {
    return (
      <Button
        variant='text'
        color='primary'
        style={{ paddingLeft: '0' }}
        onClick={() => retryApiCall(id)}
      >
        {t('retry')}
      </Button>
    )
  }
  const columns: Array<Column<ApiCall>> = [
    {
      title: capitalize(t('name')),
      field: 'name',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 90,
      // eslint-disable-next-line react/display-name
      render: ({ id, title }) => <LinkCell id={id} title={title} />,
    },
    {
      title: 'Method',
      field: 'method',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 90,
      // eslint-disable-next-line react/display-name
      render: ({ request: { method } }) => <Text>{method}</Text>,
    },
    {
      title: t('endpoint'),
      field: 'name',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 120,
      // eslint-disable-next-line react/display-name
      render: ({ request: { endpoint } }) => <Text> {endpoint}</Text>,
    },
    {
      title: t('called_at'),
      field: 'called_at',
      sorting: true,
      filtering: false,
      grouping: true,
      searchable: false,
      defaultSort: 'asc',
      width: 150,
      customSort: (apiCall, nextApiCall) => {
        const prevDate = apiCall.created_at
        const nextDate = nextApiCall.created_at

        if (new Date(prevDate) > new Date(nextDate)) return -1
        if (new Date(prevDate) < new Date(nextDate)) return 1
        return 0
      },
      // eslint-disable-next-line react/display-name
      render: apiCall => (
        <Text>
          {format(new Date(apiCall.created_at), 'd-LLL-yyyy K:mm:ss a')}
        </Text>
      ),
    },
    {
      title: t('status'),
      field: 'status',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      // eslint-disable-next-line react/display-name
       // @ts-expect-error - TODO: fix this
      render: ({ status }) => t(`api_call_${status.toLowerCase()}`),

      width: 90,
    },
    {
      title: t('response'),
      field: 'responses',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      // eslint-disable-next-line react/display-name
      render: ({ responses, status }) =>
        status === 'Pending' || isEmpty(responses) ? (
          '--'
        ) : (
          <RequestStatus status={responses[responses.length - 1].status} />
        ),
      width: 90,
    },
    {
      title: capitalize(t('action_other')),
      field: 'actions',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      render: renderActionRow,
      width: 90,
    },
  ]
  return { columns }
}
