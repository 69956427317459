import { isNil } from 'lodash'
import { useGenerateRetoolEmbedUrlQuery } from '../../generated/types-orchestration'

interface UseRetoolHook {
  url: string | null
  loading: boolean
  error?: any
}

export interface retoolResponse {
  url: string | null
}

interface Request {
  user: { firstName: string; lastName: string; email: string; id: string }
  groupIds: Array<string>
  landingPageUuid: string
  releaseVersion?: string
}

export const useRetool = (request: Request): UseRetoolHook => {
  const { user, groupIds, landingPageUuid, releaseVersion } = request

  const { data, loading, error } = useGenerateRetoolEmbedUrlQuery({
    variables: {
      groupIds,
      userInfo: user,
      landingPageUuid,
      releaseVersion,
    },
    // urls are single use therefore we don't want to cache them
    fetchPolicy: 'no-cache',
  })

  if (!isNil(error)) {
    return { loading: false, url: null, error }
  }

  const url = data?.generateRetoolEmbedUrl.url ?? null

  return {
    url,
    loading,
  }
}
