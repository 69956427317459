import { isEmpty, isNil } from 'lodash'
import React, { type FC } from 'react'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

import {
  type Activity,
  ActivityAction,
  ActivityObjectType,
  ActivitySubjectType,
} from '../../../types'

interface Props {
  activity: Activity
  careflowStopExplanation?: string
}

export const ActivitySummary: FC<Props> = ({
  activity,
  careflowStopExplanation,
}) => {
  const { subject, action, object, indirect_object } = activity

  const STEP_ACTIVITY_MESSAGES = {
    [ActivityAction.Activate]: 'activityfeed:step_activated',
    [ActivityAction.Complete]: 'activityfeed:step_completed',
    [ActivityAction.Stopped]: 'activityfeed:step_stopped',
    [ActivityAction.Postponed]: 'activityfeed:step_waiting',
    [ActivityAction.Discarded]: 'activityfeed:step_discarded',
  } as const

  const PATHWAY_ACTIVITY_MESSAGES = {
    [ActivityAction.Activate]: 'activityfeed:pathway_activated',
    [ActivityAction.Complete]: 'activityfeed:pathway_completed',
    [ActivityAction.IsWaitingOn]: 'activityfeed:pathway_waiting',
    [ActivityAction.Stopped]: 'activityfeed:pathway_stopped_with_reason',
  } as const

  const ACTION_ACTIVITY_MESSAGES = {
    [ActivityAction.Discarded]: 'activityfeed:action_discarded',
  } as const

  const TRACK_ACTIVITY_MESSAGES = {
    [ActivityAction.Activate]: 'activityfeed:track_started',
    [ActivityAction.Stopped]: 'activityfeed:track_stopped',
    [ActivityAction.Complete]: 'activityfeed:track_completed',
  } as const

  // Handle all other cases using mappings
  const messageMap: Partial<Record<ActivityObjectType, Partial<Record<ActivityAction, string>>>> = {
    [ActivityObjectType.Step]: STEP_ACTIVITY_MESSAGES,
    [ActivityObjectType.Pathway]: PATHWAY_ACTIVITY_MESSAGES,
    [ActivityObjectType.Action]: ACTION_ACTIVITY_MESSAGES,
    [ActivityObjectType.Track]: TRACK_ACTIVITY_MESSAGES,
  } as const

  const getActivitySummary = (
    objectType: ActivityObjectType,
    action: ActivityAction,
  ): string | undefined | React.ReactNode => {
    // Handle special case for Track Added
    if (
      objectType === ActivityObjectType.Track &&
      action === ActivityAction.Added
    ) {
      return subject.type === ActivitySubjectType.ApiCall
        ? t('activityfeed:track_added_by_api_call', { object })
        : t('activityfeed:track_added_by_user', { subject, object })
    }
    // Handle special case for Reminder
    if (objectType === ActivityObjectType.Reminder) {
      return (
        <Trans
          i18nKey='reminder_activity_summary'
          ns='activityfeed'
          values={{
            firstName: indirect_object?.name,
            email: isNil(indirect_object?.email) ? '' : indirect_object?.email,
          }}
        />
      )
    }

    const messageKey = messageMap[objectType]?.[action]
    if (!isNil(messageKey)) {
      // Special case for pathway stopped with reason
      if (
        objectType === ActivityObjectType.Pathway &&
        action === ActivityAction.Stopped
      ) {
        const pathwayStatusExplanation = isNil(careflowStopExplanation) || isEmpty(careflowStopExplanation) ? 'No reason provided' : careflowStopExplanation
        // @ts-expect-error - TODO: fix this
        return t(messageKey,{ pathwayStatusExplanation })
      }
      // @ts-expect-error - TODO: fix this
      return t(messageKey, { subject, object })
    }
    return null
  }

  return (
    <div className='flex justify-between items-center w-full'>
      <div className='text-lg text-neutral-500'>
        {getActivitySummary(object.type, action)}
      </div>
    </div>
  )
}

ActivitySummary.displayName = 'ActivitySummary'
