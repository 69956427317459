import {
  DataPointValueType,
  FormContextProvider,
  UserQuestionType,
} from '@awell/ui-kit/components/FormBuilder'
import { Form } from '@awell/ui-kit/components/FormBuilder/Form'
import { ScrollContainer } from '@awell/ui-kit/components/ScrollContainer'
import { FormMode } from '@awell/ui-kit/enums'
import { Type, TypeVariants } from '@awell/ui-kit/src/components/Typography'
import { spacing } from '@awell/ui-kit/src/utils/style-guide'
import { makeStyles } from '@material-ui/core'
import { compose, defaultTo, map } from 'lodash/fp'
import React, { type FC } from 'react'
import { t } from 'i18next'
import { FormSettingsContextProvider } from '@awell/ui-kit/components/FormBuilder/contexts'
import { useBaselineInfo } from '../../hooks/useBaselineInfo'
import { useNotifications } from '../../hooks/useNotifications'
import {
  type AnswerInput,
  type BaselineInfoInput,
  type DataPointDefinition,
  type Question,
  QuestionType,
  useSaveBaselineInfoMutation,
} from './types'

export const mapDataPointValueTypeToUserQuestionType = (
  dataPointValueType: DataPointValueType,
): UserQuestionType => {
  switch (dataPointValueType) {
    case DataPointValueType.Number:
      return UserQuestionType.Number
    case DataPointValueType.Boolean:
      return UserQuestionType.YesNo
    case DataPointValueType.Date:
      return UserQuestionType.Date
    case DataPointValueType.String:
      return UserQuestionType.ShortText
    case DataPointValueType.Telephone:
      return UserQuestionType.Telephone
    case DataPointValueType.Json:
      return UserQuestionType.LongText
    default:
      throw new Error(
        `DataPointValueType type ${dataPointValueType} is not handled`,
      )
  }
}

const mapDataPointDefinitionToQuestion = (
  dataPointDefinition: DataPointDefinition,
): Question => {
  const userQuestionType = mapDataPointValueTypeToUserQuestionType(
    dataPointDefinition.valueType,
  )
  return {
    id: dataPointDefinition.id,
    title: dataPointDefinition.title,
    dataPointValueType: dataPointDefinition.valueType,
    questionType: QuestionType.Input,
    // @ts-expect-error missing id and value is string but good enough for now, until we decide to build baseline info form
    options: dataPointDefinition.possibleValues,
    userQuestionType,
    questionConfig: {
      mandatory: true,
    },
  }
}

const useStyles = makeStyles({
  container: {
    padding: spacing.xs,
    margin: '0 auto',
    maxWidth: '60ch',
  },
  baseline_info_tip: {
    paddingBottom: spacing.xs,
  },
})

interface BaselineInfoFormContainerProps {
  pathwayId: string
}

export const BaselineInfoFormContainer: FC<BaselineInfoFormContainerProps> = ({
  pathwayId,
}) => {
  const classes = useStyles()
  
  const { notifyError } = useNotifications()
  const { baselineDataPoints } = useBaselineInfo(pathwayId)
  const [saveBaselineInfo] = useSaveBaselineInfoMutation()

  const questions = compose(
    map(mapDataPointDefinitionToQuestion),
    map('definition'),
  )(baselineDataPoints)

  const formResponseToBaselineInput = compose(
    defaultTo([]),
    map(({ question_id: questionId, value }) => ({
      data_point_definition_id: questionId,
      value,
    })),
  )
  const handleSubmit = async (
    baselineInfoResponse: Array<AnswerInput>,
  ): Promise<void> => {
    try {
      const baselineInfo: Array<BaselineInfoInput> =
        formResponseToBaselineInput(baselineInfoResponse)
      await saveBaselineInfo({
        variables: {
          input: {
            pathway_id: pathwayId,
            baseline_info: baselineInfo,
          },
        },
        refetchQueries: ['GetBaselineInfo'],
        awaitRefetchQueries: true,
      })
    } catch (error) {
      notifyError({
        message: 'Something went wrong with saving baseline info',
        error,
      })
    }
  }
  return (
    <ScrollContainer>
      <FormSettingsContextProvider
        mode={FormMode.Submittable}
        labels={{
          mandatory_error_text: t('form_mandatory_error_text'),
          invalid_error_text: t('form_invalid_error_text'),
          pagination_currently_shown_items_info: ({
            firstShownQuestion,
            lastShownQuestion,
            totalCount,
          }) =>
            t('pagination_currently_shown_items_info', {
              firstShownQuestion,
              lastShownQuestion,
              totalCount,
            }),
        }}
      >
        <FormContextProvider
          questions={questions}
          evaluateDisplayConditions={async () => await Promise.resolve([])}
          onSubmit={handleSubmit}
        >
          <div className={classes.container}>
            <div className={classes.baseline_info_tip}>
              <Type variant={TypeVariants.regular} responsive span>
                {t('baseline_info_tip')}
              </Type>
            </div>
            <Form />
          </div>
        </FormContextProvider>
      </FormSettingsContextProvider>
    </ScrollContainer>
  )
}

BaselineInfoFormContainer.displayName = 'BaselineInfoFormContainer'
