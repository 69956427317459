import React, { type FC } from 'react'
import { isNil, isEmpty } from 'lodash'
import clsx from 'clsx'

interface Props {
  stepLabelColor: string
  stepLabelName?: string
}

export const ActivityLabel: FC<Props> = ({ stepLabelColor, stepLabelName }) => {

  const getLabelColorClass = (color: string): string => {
    const colorMap = {
      stepDefault: 'bg-green-100',
      red: 'bg-red-100',
      teal: 'bg-teal-100',
      blue: 'bg-blue-300',
      green: 'bg-green-100',
      orange: 'bg-orange-100',
      purple: 'bg-violet-100',
    }
    return colorMap[color as keyof typeof colorMap]
  }

  // for some reason isNil() returns false for empty strings ?!
  // don't have time nor desire to look into it right now
  if (isNil(stepLabelName) || isEmpty(stepLabelName)) {
    return <></>
  }

  return (
    <div className='absolute left-0 -top-9'>
      <div
        className={clsx(
          'flex items-center h-8 px-2 py-1 rounded-t-md font-bold uppercase',
          getLabelColorClass(stepLabelColor),
        )}
      >
        {stepLabelName}
      </div>
    </div>
  )
}

ActivityLabel.displayName = 'ActivityLabel'
