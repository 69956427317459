import { useEffect } from 'react'
import { t } from 'i18next'
import { useNotifications } from '../useNotifications'
import type { EmrReport } from './types'
import { useGetEmrReportLazyQuery } from './types'

interface UseGetEmrDetailHook {
  loading: boolean
  report: EmrReport
}

export const useEmrDetail = (
  reportId: string,
): UseGetEmrDetailHook => {
  const { notifyError } = useNotifications()
  
  const [getEmrReport, { loading, data, error }] = useGetEmrReportLazyQuery({
    variables: { id: reportId },
  })

  useEffect(() => {
    if (reportId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getEmrReport()
    }
  }, [reportId])

  if (error) {
    notifyError({
      message: t('error_load_report'),
      error,
    })
  }

  return {
    loading,
    report: data?.emrReport?.report as EmrReport,
  }
}
