import { useNavigate } from '@reach/router'
import { isEmpty, isNil } from 'lodash'
import React, {
  createContext,
  type FC,
  useContext,
  useEffect,
  useState,
} from 'react'
import { type Pathway, PathwayStatus, usePathway } from '../../hooks/usePathway'
import { usePathwayContext } from '../../hooks/usePathwayContext'
import {
  ActivityAction,
  ActivityObjectType,
  ActivityStatus,
} from '../ActivityDetails/types'
import { type Activity, type Element, ElementType } from './types'

const getActivityIdFromUrl = (): string | null => {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get('activityId')
}

const setActivityIdQueryParam = (id: string): void => {
  // set tab value as query param in current url i.e. ?tab=0
  const url = new URL(window.location.href)
  url.searchParams.set('activityId', id)
  window.history.pushState({}, '', url.toString())
}

interface PathwayContextInterface {
  loading: boolean
  activities: Array<Activity>
  pendingActivities: Array<Activity>
  sidePanelOpen: boolean
  setSidePanelOpen: (sidePanelOpen: boolean) => void
  selectedActivity?: Activity
  onSelectActivity: (selectedActivity: Activity | undefined) => void
  setPanelFromElement: (element: Element | undefined) => void
  onClosePanel: () => void
  onSelectElement: (element: Element | undefined) => void
  selectedElement: Element | undefined
  filteredActivities: Array<Activity>
  pathwayIsCompleted: boolean
  pathwayIsActive: boolean
  pathwayStatusExplanation: string
  patientId: string
  pathway: Pathway | null
}

const initialContext: PathwayContextInterface = {
  loading: true,
  activities: [],
  pendingActivities: [],
  sidePanelOpen: true,
  setSidePanelOpen: () => null,
  selectedActivity: undefined,
  onSelectActivity: () => null,
  setPanelFromElement: () => null,
  onClosePanel: () => null,
  onSelectElement: () => null,
  selectedElement: undefined,
  filteredActivities: [],
  pathwayIsCompleted: false,
  pathwayIsActive: false,
  pathwayStatusExplanation: '',
  patientId: '',
  pathway: null
}

const PathwayContext = createContext<PathwayContextInterface>(initialContext)

export const PathwayContextProvider: FC = ({ children }) => {
  const { pathwayId } = usePathwayContext()
  const navigate = useNavigate()
  const { loading, pathway } = usePathway(pathwayId)
  const activities = pathway?.activities ?? []
  const [sidePanelOpen, setSidePanelOpen] = useState(false)
  const [selectedActivity, setSelectedActivity] = useState<Activity>()
  const [filteredActivities, setFilteredActivities] = useState<Array<Activity>>(
    [],
  )
  const [selectedElement, setSelectedElement] = useState<Element>()

  const pendingActivities = activities.filter(
    activity =>
      activity.isUserActivity && activity.status === ActivityStatus.Active,
  )

  /**
   * This function checks whether a given activity should be displayed
   * in the track or step activities panel view
   */
  const isActivityVisibleInSidePanel = (activity: Activity): boolean =>
    (activity.isUserActivity ||
      activity.object.type === ActivityObjectType.Calculation ||
      activity.object.type === ActivityObjectType.EmrReport) &&
    activity.action !== ActivityAction.Stopped

  /**
   * This function handles clicking the 'close' button on the panel,
   * which behaves differently depending on the state of the panel
   */
  const onClosePanel = (): void => {
    if (isNil(selectedActivity)) {
      setSidePanelOpen(false)
    } else {
      setSelectedActivity(undefined)
    }
    // clear activityId query param if it's present
    const activityId = getActivityIdFromUrl()
    if (!isNil(activityId)) {
      void navigate(window.location.pathname)
    }
  }

  /**
   * This function handles clicks on the timeline elements and sets up
   * the state of the panel depending on whether an action or a step is selected
   */
  const setPanelFromElement = (element: Element | undefined): void => {
    if (isNil(element)) {
      setSelectedElement(undefined)
      setFilteredActivities([])
      setSelectedActivity(undefined)
      return
    }
    switch (element.type) {
      case ElementType.Action: {
        const activity = activities.find(
          a => a.context?.instance_id === element.context.instance_id,
        )
        setSelectedElement(undefined)
        setFilteredActivities([])
        setSelectedActivity(activity)
        break
      }
      case ElementType.Step: {
        const filteredStepActivities = activities.filter(
          activity =>
            activity.context?.step_id === element.context.step_id &&
            isActivityVisibleInSidePanel(activity),
        )
        setSelectedElement(element)
        setFilteredActivities(filteredStepActivities)
        setSelectedActivity(undefined)
        break
      }
      case ElementType.Track: {
        const filteredTrackActivities = activities.filter(
          activity =>
            activity.context?.track_id === element.context.track_id &&
            isActivityVisibleInSidePanel(activity),
        )
        setSelectedElement(element)
        setFilteredActivities(filteredTrackActivities)
        setSelectedActivity(undefined)
        break
      }
      default: {
        setSelectedElement(undefined)
        setFilteredActivities([])
        setSelectedActivity(undefined)
      }
    }
  }

  // Open the side panel when there are pending activities
  useEffect(() => {
    if (!isEmpty(pendingActivities)) {
      setSidePanelOpen(true)
    }
  }, [pendingActivities.length])

  // Open the side panel when there is a selected activity or element and
  // close it when there is no selected activity, element or pending activities
  useEffect(() => {
    if (!isNil(selectedActivity) || !isNil(selectedElement)) {
      setSidePanelOpen(true)
    }
    if (
      isNil(selectedActivity) &&
      isNil(selectedElement) &&
      isEmpty(pendingActivities)
    ) {
      setSidePanelOpen(false)
    }
  }, [selectedActivity, selectedElement])

  // Ensure activity and element selections are reset when panel is closed
  useEffect(() => {
    if (!sidePanelOpen) {
      setSelectedActivity(undefined)
      setSelectedElement(undefined)
      setFilteredActivities([])
    }
  }, [sidePanelOpen])

  // Update selected activity on activity status change and subactivities update
  useEffect(() => {
    if (!isNil(selectedActivity)) {
      const activity = activities.find(e => e.id === selectedActivity.id)
      // Prevents this code to crash if the activity is not found
      if (!isNil(activity)) {
        const shouldUpdateSelectedActivityStatus =
          activity.status !== selectedActivity.status
        const shouldUpdateSelectedActivitySubActivities =
          activity.sub_activities.length !==
          selectedActivity.sub_activities.length
        if (
          shouldUpdateSelectedActivityStatus ||
          shouldUpdateSelectedActivitySubActivities
        ) {
          setSelectedActivity(activity)
        }
      }
    }
  }, [selectedActivity, activities])

  useEffect(() => {
    const activityId = getActivityIdFromUrl()
    // we have some activities and no activity is currently viewed
    if (!isNil(activityId) && isNil(selectedActivity)) {
      const activity = activities.find(a => a.id === activityId)
      if (!isNil(activity)) {
        setSelectedActivity(activity)
      }
    }
  }, [activities])

  useEffect(() => {
    if (!isNil(selectedActivity)) {
      setActivityIdQueryParam(selectedActivity.id)
    }
  }, [selectedActivity])

  const pathwayIsCompleted = pathway?.status === PathwayStatus.Completed
  const pathwayIsActive = pathway?.status === PathwayStatus.Active
  const pathwayStatusExplanation = pathway?.status_explanation ?? ''
  const patientId = pathway?.patient.id ?? ''

  return (
    <PathwayContext.Provider
      value={{
        loading,
        activities,
        pendingActivities,
        sidePanelOpen,
        setSidePanelOpen,
        selectedActivity,
        onSelectActivity: setSelectedActivity,
        setPanelFromElement,
        onClosePanel,
        onSelectElement: setSelectedElement,
        selectedElement,
        filteredActivities,
        pathwayIsCompleted,
        pathwayIsActive,
        pathwayStatusExplanation,
        patientId,
        pathway
      }}
    >
      {children}
    </PathwayContext.Provider>
  )
}

export const usePathwayContextProvider = (): PathwayContextInterface =>
  useContext(PathwayContext)
