/* eslint-disable react/display-name */
import { type Column } from '@awell/ui-kit/components/Table'
import React, { type ReactElement } from 'react'
import { t } from 'i18next'
import { formatRelative, parseISO } from 'date-fns'
import { type Activity, ActivityObjectType } from './types'
import { Link } from '@reach/router'
import { isEmpty, isNil } from 'lodash'
import { IconLabelCell } from '../IconLabelCell'
import { ActivityTypeIcon } from '../ActivityTypeIcon'
import { Search } from '@awell/ui-kit/components/Icons'
import { enGB, enUS } from 'date-fns/locale'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper/IconWrapper'
import { Tooltip } from '@awell/ui-kit/components/Tooltip'
import { colors } from '@awell/ui-kit/utils/style-guide'

export const useWorkingListColumns = (): {
  columns: Array<Column<Activity>>
} => {
  

  const getPatientName = (activity: Activity): string => {
    if (
      !isNil(activity.indirect_object) &&
      !isEmpty(activity.indirect_object?.name)
    ) {
      return activity.indirect_object?.name
    }
    return t('anonymous')
  }

  const getNonPatientName = (activity: Activity): string | undefined => {
    // FOR M2M ACTIVITIES
    if (!activity.isUserActivity) {
      return activity.object.name ?? ''
    }
    // FOR NON-PATIENT ACTIVITIES
    if (activity.indirect_object?.type !== ActivityObjectType.Patient) {
      return activity.indirect_object?.name ?? ''
    }
  }

  const renderActivityStakeholderName = (
    activity: Activity,
  ): ReactElement | string => {
    const nonPatientName = getNonPatientName(activity)
    if (!isNil(nonPatientName)) {
      return nonPatientName
    }

    return (
      <Link
        to={`/patients/${activity.indirect_object?.id ?? ''}`}
        id={activity.id}
        key={activity.id}
      >
        {getPatientName(activity)}
      </Link>
    )
  }

  const columns: Array<Column<Activity>> = [
    {
      id: (activity: Activity) => activity.id,
      title: t('workinglist:column_patient'),
      field: 'patientName',
      type: 'string',
      sorting: false,
      filtering: false,
      searchable: false,
      render: renderActivityStakeholderName,
      width: 90,
    },
    {
      title: t('workinglist:column_step_title'),
      field: 'stepTitle',
      type: 'string',
      sorting: false,
      filtering: false,
      searchable: false,
      render: (activity: Activity): string => activity?.container_name ?? '-',
      width: 120,
    },
    {
      title: t('workinglist:column_activity_title'),
      field: 'activityTitle',
      type: 'string',
      sorting: false,
      filtering: false,
      searchable: false,
      render: (activity: Activity): ReactElement => (
        <IconLabelCell
          label={activity.object.name}
          icon={<ActivityTypeIcon type={activity.object.type} />}
          status={activity.status}
        />
      ),
      width: 120,
    },
    {
      title: t('workinglist:column_activity_date'),
      field: 'date',
      type: 'date',
      defaultSort: 'desc',
      sorting: false,
      filtering: false,
      searchable: false,
      render: (activity: Activity): string =>
        formatRelative(parseISO(activity.date), new Date(), {
          // match locale to navigator window language
          locale: window.navigator.language?.includes('en-US') ? enUS : enGB,
        }),
      width: 50,
    },
    {
      title: t('workinglist:column_stream_id'),
      field: 'patient',
      type: 'string',
      sorting: false,
      filtering: false,
      searchable: false,
      width: 30,
      render: (activity: Activity): ReactElement => {
        return (
          <Tooltip info={t('workinglist:see_pathway')} arrow placement='top'>
            <span>
              <a
                href={`/pathway/${activity.stream_id}/activity-feed?activityId=${activity.id}`}
                rel={'noopener noreferrer'}
                data-heap={`working-list-${activity.id}`}
              >
                <IconWrapper
                  inline
                  size='m'
                  backgroundColor={colors.brand100}
                  background
                  rounded
                  light
                >
                  <Search />
                </IconWrapper>
              </a>
            </span>
          </Tooltip>
        )
      },
    },
  ]
  return { columns }
}
