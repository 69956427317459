import {
  AlertModal,
  type AlertModalLabels,
} from '@awell/ui-kit/components/AlertModal'
import { Button, IconButton } from '@awell/ui-kit/components/Button'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'
import { CopyOutline, QrCode } from '@awell/ui-kit/components/Icons'
import { Tooltip } from '@awell/ui-kit/components/Tooltip'
import { Text, Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { colors, rounded, spacing } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core'
import { isNil, noop } from 'lodash'
import React, { useState, type FC, useEffect } from 'react'
import { t } from 'i18next'
import { toDataURL } from 'qrcode'
import { Skeleton } from '@awell/ui-kit/components/Skeleton'
import { PathwayStatus } from 'app-care/src/generated/types-orchestration'

interface HostedPagesQRButtonProps {
  hostedPagesUrlForPatient: string | undefined
  pathwayStatus: PathwayStatus
}

const useStyles = makeStyles({
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacing.xxxs,
    justifyContent: 'center',
  },
  linkDetail: {
    minWidth: 460,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: colors.neutralLight40,
    ...rounded.s,
  },
  copyButton: {
    // disable hover effect
    minWidth: 'fit-content',
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  url: {
    paddingLeft: spacing.xsm,
    flexGrow: 1,
    width: 'fit-content',
    borderRadius: `0 ${spacing.xxs} ${spacing.xxs} 0`,
  },
  copyButtonContent: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxs,
    '& *': {
      color: colors.neutralMid200,
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  qrCodeImage: {
    display: 'block',
    margin: 'auto',
  },
})

export const HostedPagesQRButton: FC<HostedPagesQRButtonProps> = ({
  hostedPagesUrlForPatient,
  pathwayStatus,
}) => {
  const [open, setOpen] = useState(false)
  
  const classes = useStyles()
  const [hasCopied, setHasCopied] = useState(false)
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState<string>()

  useEffect(() => {
    if (!isNil(hostedPagesUrlForPatient)) {
      void toDataURL(hostedPagesUrlForPatient, {
        scale: 7,
      }).then(url => {
        setQrCodeImageUrl(url)
      })
    }
  }, [hostedPagesUrlForPatient])

  const onClose = (): void => {
    setOpen(false)
  }

  const handleCopy = async (text: string): Promise<void> => {
    setHasCopied(true)
    await navigator.clipboard.writeText(text)
    setTimeout(() => {
      setHasCopied(false)
    }, 2000)
  }

  const labels: AlertModalLabels = {
    heading: t('qr_code_for_hosted_pages'),
    primaryAction: '',
    secondaryAction: t('close'),
  }

  const qrButtonIsDisabled =
    isNil(hostedPagesUrlForPatient) ||
    pathwayStatus === PathwayStatus.Completed ||
    pathwayStatus === PathwayStatus.Stopped

  const getTooltipText = (): string => {
    if (isNil(hostedPagesUrlForPatient)) {
      return t(`qr_button_tooltip_disabled_reason_link_not_available`)
    }

    if (qrButtonIsDisabled) {
      // @ts-expect-error - TODO: fix this
      return t(`qr_button_tooltip_disabled_reason_pathway_${pathwayStatus}`)
    }

    return t(`qr_button_tooltip_text`)
  }

  return (
    <>
      <Tooltip info={getTooltipText()} arrow enterDelay={200} exitDelay={100}>
        <span>
          <IconButton
            variant='secondary'
            onClick={() => {
              setOpen(true)
            }}
            disabled={qrButtonIsDisabled}
          >
            <QrCode />
          </IconButton>
        </span>
      </Tooltip>

      <AlertModal
        labels={labels}
        open={open}
        onAction={noop}
        onClose={onClose}
        hidePrimaryAction
      >
        <Type variant={TypeVariants.subtle}>
          {t('qr_code_for_hosted_pages_description')}
        </Type>

        {!isNil(qrCodeImageUrl) ? (
          <img className={classes.qrCodeImage} src={qrCodeImageUrl} />
        ) : (
          <Skeleton className={classes.qrCodeImage} width={330} height={330} />
        )}

        <Type variant={TypeVariants.regular} className={classes.textCenter}>
          {t('or_copy_link_below')}
        </Type>
        <div className={classes.linkContainer}>
          <>
            <div className={classes.linkDetail}>
              <Tooltip
                info={hostedPagesUrlForPatient ?? ''}
                arrow
                enterDelay={2000}
                highlighted
              >
                <a
                  href={hostedPagesUrlForPatient}
                  onClick={noop}
                  target='_blank'
                  rel='noreferrer'
                  className={classes.url}
                  style={{
                    color: colors.neutralDark800,
                  }}
                >
                  {hostedPagesUrlForPatient}
                </a>
              </Tooltip>
              <Tooltip
                enterDelay={0}
                exitDelay={100}
                info={
                  hasCopied
                    ? t('copied_url_button_title')
                    : t('copy_url_button_title')
                }
                arrow
                placement='top'
              >
                <Button
                  variant='text'
                  overrideRootClass={classes.copyButton}
                  style={{
                    paddingTop: spacing.xxs,
                    paddingBottom: spacing.xxs,
                  }}
                  onClick={() => {
                    void handleCopy(hostedPagesUrlForPatient ?? '')
                  }}
                >
                  <div className={classes.copyButtonContent}>
                    <IconWrapper size='xxs'>
                      <CopyOutline />
                    </IconWrapper>
                    <Text>{t('copy_url_button_title')}</Text>
                  </div>
                </Button>
              </Tooltip>
            </div>
          </>
        </div>
      </AlertModal>
    </>
  )
}
