import { type Column } from '@awell/ui-kit/components/Table'
import React, { useState } from 'react'
import { t } from 'i18next'
import { RequestStatus } from '@awell/ui-kit/components/RequestStatus/RequestStatus'
import { capitalize } from 'lodash'
import { AlertModal } from '@awell/ui-kit/components/AlertModal'
import { Button } from '@awell/ui-kit/components/Button'
import ReactJson from 'react-json-view'
import { Text } from '@awell/ui-kit/components/Typography'
import { format } from 'date-fns'
import { custom_json_parser } from '@awell/ui-kit/utils'
import { useStyles } from '../useStyles'
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard'
import { type WebhookCallResponse } from '../../../generated/types-orchestration'

export const useWebhooksPreviewListColumns = (): {
  columns: Array<Column<WebhookCallResponse>>
} => {
  
  const classes = useStyles()

  const renderActionRow = ({ body }: WebhookCallResponse) => {
    const [modalOpen, toggleModal] = useState(false)
    const { copyToClipboard } = useCopyToClipboard()

    const handleCopyToClipboard = () => {
      copyToClipboard(body)
      toggleModal(false)
    }

    const jsonBody = custom_json_parser(body)
    return (
      <>
        <Button
          variant='text'
          color='primary'
          style={{ paddingLeft: '0' }}
          onClick={() => toggleModal(true)}
        >
          {t('view_response')}
        </Button>
        <AlertModal
          labels={{
            heading: t('response_body'),
            primaryAction: t('copy_to_clipboard'),
            secondaryAction: t('close'),
          }}
          open={modalOpen}
          onAction={handleCopyToClipboard}
          onClose={() => toggleModal(false)}
        >
          <div className={classes.jsonContainer}>
            {typeof jsonBody === 'string' ? (
              <Text>{t('unavailable_response')}</Text>
            ) : (
              <ReactJson src={jsonBody} enableClipboard={false} />
            )}
          </div>
        </AlertModal>
      </>
    )
  }
  const columns: Array<Column<WebhookCallResponse>> = [
    {
      title: t('timestamp'),
      field: 'date',
      sorting: true,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 200,
      defaultSort: 'asc',
      customSort: ({ date }, { date: nextDate }) => {
        if (new Date(date) > new Date(nextDate)) return -1
        if (new Date(date) < new Date(nextDate)) return 1
        return 0
      },
      // eslint-disable-next-line react/display-name
      render: ({ date }) => (
        <Text>{format(new Date(date), 'd-LLL-yyyy K:mm:ss a')}</Text>
      ),
    },
    {
      title: t('status'),
      field: 'status',
      sorting: false,
      filtering: false,
      grouping: true,
      searchable: false,
      width: 150,
      // eslint-disable-next-line react/display-name
      render: ({ status }) => <RequestStatus status={status} />,
    },
    {
      title: capitalize(t('action_other')),
      field: 'response_time',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      render: renderActionRow,
      width: 90,
    },
  ]
  return { columns }
}
