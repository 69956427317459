import type { Column } from '@awell/ui-kit/components/Table'
import React from 'react'
import { capitalize, isEmpty } from 'lodash'
import { t } from 'i18next'
import { LinkCell } from '@awell/ui-kit/components/Table/LinkCell'
import { Button } from '@awell/ui-kit/components/Button'
import { RequestStatus } from '@awell/ui-kit/components/RequestStatus'
import { Text } from '@awell/ui-kit/components/Typography'
import { format } from 'date-fns'
import { getWebhookCallDate } from '@awell/ui-kit/utils/getWebhookCallDate'
import { useRetryWebhookCall } from '../../../hooks/useRetryWebhookCall'
import { type WebhookCall } from '../../../generated/types-orchestration'
import type { WebhookEventType, WebhookStatus } from '../types'

export const useWebhookCallsListColumns = (): {
  columns: Array<Column<WebhookCall>>
} => {
  
  const { retryWebhook } = useRetryWebhookCall()

  const getWebhookEventTypeKey = (eventType: WebhookEventType) => {
    return `webhook.${eventType}` as const
  }

  const getWebhookStatusKey = (status: WebhookStatus) => {
    return `WEBHOOK_${status}` as const
  }

  const renderActionRow = ({ id, webhook_name }: WebhookCall) => {
    return (
      <Button
        variant='text'
        color='primary'
        style={{ paddingLeft: '0' }}
        onClick={() => retryWebhook(id, webhook_name)}
      >
        {t('retry')}
      </Button>
    )
  }
  const columns: Array<Column<WebhookCall>> = [
    {
      title: t('webhook'),
      field: 'name',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 120,
      // eslint-disable-next-line react/display-name
      render: ({ webhook_name, id }) => (
        <LinkCell id={id} title={webhook_name} />
      ),
    },
    {
      title: t('called_at'),
      field: 'called_at',
      sorting: true,
      filtering: false,
      grouping: true,
      searchable: false,
      defaultSort: 'asc',
      width: 150,
      customSort: (webhookCall, nextWebhookCall) => {
        const prevDate = getWebhookCallDate(webhookCall)
        const nextDate = getWebhookCallDate(nextWebhookCall)

        if (new Date(prevDate) > new Date(nextDate)) return -1
        if (new Date(prevDate) < new Date(nextDate)) return 1
        return 0
      },
      // eslint-disable-next-line react/display-name
      render: webhookCall => (
        <Text>
          {format(getWebhookCallDate(webhookCall), 'd-LLL-yyyy K:mm:ss a')}
        </Text>
      ),
    },
    {
      title: t('status'),
      field: 'status',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      // eslint-disable-next-line react/display-name
      render: ({ status }) => t(getWebhookStatusKey(status as WebhookStatus)),

      width: 90,
    },
    {
      title: t('response'),
      field: 'responses',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      // eslint-disable-next-line react/display-name
      render: ({ responses, status }) =>
        status === 'PENDING' || isEmpty(responses) ? (
          '--'
        ) : (
          <RequestStatus status={responses[responses.length - 1].status} />
        ),
      width: 90,
    },
    {
      title: t('trigger_event'),
      field: 'event_type',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      render: ({ event_type }) => t(getWebhookEventTypeKey(event_type as WebhookEventType)),
      width: 90,
    },
    {
      title: capitalize(t('action_other')),
      field: 'actions',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      render: renderActionRow,
      width: 90,
    },
  ]
  return { columns }
}
