import { type Column } from '@awell/ui-kit/components/Table'
import React from 'react'
import { t } from 'i18next'
import { LinkCell } from '@awell/ui-kit/components/Table/LinkCell'
import { type DetailsForPublishedPathwayDefinition } from '../../../hooks/usePublishedPathwayDefinitions'
import { isEmpty } from 'lodash'

interface Pathway {
  id: string
  title: string
}

export const usePathwayListColumns = (): {
  columns: Array<Column<DetailsForPublishedPathwayDefinition>>
} => {
  

  const renderPathwayTitle = React.useMemo(
    // eslint-disable-next-line react/display-name
    () => (pathway: Pathway) =>
      <LinkCell title={pathway.title} id={pathway.id} />,
    [],
  )
  const columns: Array<Column<DetailsForPublishedPathwayDefinition>> = [
    {
      title: t('pathway_table_column_pathway_name'),
      field: 'title',
      type: 'string',
      sorting: true,
      customSort: (a, b) => {
        const aTitle = a.title ?? ''
        const bTitle = b.title ?? ''
        return aTitle.localeCompare(bTitle)
      },
      filtering: false,
      searchable: false,
      width: 90,
      render: renderPathwayTitle,
    },
    {
      title: t('pathway_table_column_active_activities'),
      field: 'activeActivities',
      sorting: true,
      filtering: false,
      defaultSort: 'desc',
      customSort: (a, b) => {
        const aPendingActivities = Number(a.active_activities) ?? 0
        const bPendingActivities = Number(b.active_activities) ?? 0
        return aPendingActivities - bPendingActivities
      },
      searchable: false,
      render: data => <>{data.active_activities ?? 0}</>,
      width: 30,
    },
    {
      title: t('pathway_table_column_failed_activities'),
      field: 'allFailedActivities',
      sorting: true,
      filtering: false,
      customSort: (a, b) => {
        const aFailedActivities = Number(a.failed_activities) ?? 0
        const bFailedActivities = Number(b.failed_activities) ?? 0
        return aFailedActivities - bFailedActivities
      },
      searchable: false,
      render: data => <>{data.failed_activities ?? 0}</>,
      width: 30,
    },
    {
      title: t('pathway_table_column_stakeholders_list'),
      field: 'activeStakeholdersList',
      sorting: false,
      filtering: false,
      searchable: false,
      render: data =>
        !isEmpty(data.stakeholders_with_pending_activities_list) ? (
          <>{data.stakeholders_with_pending_activities_list?.join(', ')}</>
        ) : (
          <>{'-'}</>
        ),
      width: 30,
    },
    {
      title: t('pathway_table_column_total_activities'),
      field: 'totalActivities',
      sorting: true,
      filtering: false,
      customSort: (a, b) => {
        const aTotalActivities = Number(a.total_activities) ?? 0
        const bTotalActivities = Number(b.total_activities) ?? 0
        return aTotalActivities - bTotalActivities
      },
      searchable: false,
      render: data => <>{data.total_activities ?? 0}</>,
      width: 30,
    },
    {
      title: t('pathway_table_column_active_patients'),
      field: 'activePatients',
      type: 'string',
      sorting: true,
      customSort: (a, b) => {
        const aActivePatients = Number(a.patients_with_pending_activities) ?? 0
        const bActivePatients = Number(b.patients_with_pending_activities) ?? 0
        return aActivePatients - bActivePatients
      },
      filtering: false,
      searchable: false,
      render: data => <>{data.patients_with_pending_activities ?? 0}</>,
      width: 30,
    },
    {
      title: t('pathway_table_column_all_patients'),
      field: 'allPatients',
      type: 'datetime',
      sorting: true,
      customSort: (a, b) => {
        const aTotalPatients = Number(a.total_patients) ?? 0
        const bTotalPatients = Number(b.total_patients) ?? 0
        return aTotalPatients - bTotalPatients
      },
      filtering: false,
      searchable: false,
      render: data => <>{data.total_patients ?? 0}</>,
      width: 30,
    },
    {
      title: t('pathway_table_column_active_stakeholders'),
      field: 'activeStakeholders',
      sorting: false,
      filtering: false,
      searchable: false,
      render: data => (
        <>{data.stakeholders_with_pending_activities_list?.length ?? 0}</>
      ),
      width: 30,
    },
  ]
  return { columns }
}
