import { TableSkeleton } from '@awell/ui-kit/components/Skeleton/TableSkeleton'
import { Table } from '@awell/ui-kit/components/Table'
import { type RouteComponentProps } from '@reach/router'
import { FormBlock } from 'app-care/src/components/FormBlock'
import { useActivities } from 'app-care/src/hooks/useActivities'
import React, { type FC } from 'react'
import { t } from 'i18next'
import { usePatientActivitiesColumns } from './usePatientActivitiesColumns'

import { type Patient } from '../types'
import { ActivityStatus } from '../../Pathway/types'
import { isNil } from 'lodash'
import { possiblePatientActivityTypes } from '../../../components/WorkingListTable/utils'

interface ActvitiesTabProps {
  patient: Patient
}

export const ActivitiesTab: FC<RouteComponentProps<ActvitiesTabProps>> = ({
  patient,
}) => {
  const { columns } = usePatientActivitiesColumns(patient?.id ?? '')
  
  const { loading, activities } = useActivities({
    patient_id: { eq: patient?.id ?? '' },
    activity_status: {
      in: [
        ActivityStatus.Active,
        ActivityStatus.Canceled,
        ActivityStatus.Failed,
      ],
    },
  })

  const { activities: completedActivities = [] } = useActivities({
    patient_id: { eq: patient?.id ?? '' },
    activity_status: { in: [ActivityStatus.Done] },
    activity_type: { in: possiblePatientActivityTypes },
  })

  if (
    loading ||
    isNil(patient) ||
    isNil(activities) ||
    isNil(completedActivities)
  ) {
    return <TableSkeleton rowsCount={4} cellCount={5} columns={columns} />
  }

  return (
    <>
      <FormBlock title={t('pending_activities')}>
        <Table
          rows={activities}
          emptyDataSourceMessage={t('workinglist:no_activities_found')}
          columns={columns}
          paging
          fullWidth
        />
      </FormBlock>

      <FormBlock title={t('completed_activities')}>
        <Table
          rows={completedActivities}
          emptyDataSourceMessage={t('workinglist:no_activities_found')}
          columns={columns}
          paging
          fullWidth
        />
      </FormBlock>
    </>
  )
}
