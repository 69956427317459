import { t } from 'i18next'
import {
  type StakeholderActivityObject,
  useGetActivitiesQuery,
  type Activity,
  type GetActivitiesQuery,
  type GetActivitiesQueryVariables,
} from './types'
import { useNotifications } from '../useNotifications'
import { type ApolloQueryResult } from '@apollo/client'
import { isNil } from 'lodash'

interface UseActivitiesHook {
  loading: boolean
  pagination?: GetActivitiesQuery['activities']['pagination']
  sorting?: GetActivitiesQuery['activities']['sorting']
  activities?: Array<Activity>
  stakeholders?: Array<StakeholderActivityObject>
  refetchActivities: (
    query?: Partial<GetActivitiesQueryVariables>,
  ) => Promise<ApolloQueryResult<GetActivitiesQuery>>
}

export const useActivities = (
  filters?: GetActivitiesQueryVariables['filters'],
): UseActivitiesHook => {
  const { notifyError } = useNotifications()
  
  const baseInputs: GetActivitiesQueryVariables = {
    pagination: {
      count: 10,
      offset: 0,
    },
    sorting: {
      field: 'date',
      direction: 'desc',
    },
  }

  const {
    data,
    loading,
    error,
    refetch: refetchActivities,
  } = useGetActivitiesQuery({
    variables: isNil(filters) ? { ...baseInputs } : { ...baseInputs, filters },
  })

  if (error != null) {
    notifyError({
      message: t('error_loading_activities'),
      error,
    })
    return { loading: false, refetchActivities }
  }

  if (loading) {
    return { loading: true, refetchActivities }
  }

  if (!isNil(data)) {
    return {
      loading: false,
      pagination: data.activities.pagination,
      sorting: data.activities.sorting,
      activities: data.activities.activities,
      stakeholders: data.activities.metadata?.stakeholders ?? [],
      refetchActivities,
    }
  }

  return { loading: true, activities: [], refetchActivities }
}
