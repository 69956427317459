import { t } from 'i18next'
import { useNotifications } from '../useNotifications'
import type { Activity, CalculationResult } from './types'
import {
  useGetCalculationResultsQuery,
  useGetActionCalculationIdQuery,
} from './types'

interface UseCalculationActivityHook {
  loading: boolean
  results: Array<CalculationResult>
  calculationId: string | null
}

export const useCalculationActivity = ({
  activity,
}: {
  activity: Activity
}): UseCalculationActivityHook => {
  const { id: activity_id, stream_id: pathway_id } = activity

  
  const { notifyError } = useNotifications()
  const { data, loading, error } = useGetCalculationResultsQuery({
    variables: {
      activity_id,
      pathway_id,
    },
  })
  const {
    data: calculationActionData,
    loading: calculationActionDataLoading,
    error: calculationActionDataError,
  } = useGetActionCalculationIdQuery({
    variables: { id: activity.object.id },
  })
  if (loading || calculationActionDataLoading) {
    return {
      loading,
      results: [],
      calculationId: null,
    }
  }

  if (error ?? calculationActionDataError) {
    notifyError({
      error,
      message: t('get_calculation_result_error_notification'),
    })
    return { loading: false, results: [], calculationId: null }
  }

  return {
    loading,
    results: data?.calculationResults?.result ?? [],
    calculationId: calculationActionData?.calculationAction?.calculationId ?? null,
  }
}
