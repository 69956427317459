import { t } from 'i18next'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { useNotifications } from '../useNotifications'
import { useRetryWebhookCallMutation } from './types'

interface useRetryWebhookHook {
  retryWebhook: (webhookId: string, name: string) => void
}

export const useRetryWebhookCall = (): useRetryWebhookHook => {
  const { notifyError, notify } = useNotifications()
  
  const [retryWebhookCallMutation] = useRetryWebhookCallMutation()

  const retryWebhook = async (webhookId: string, name: string) => {
    try {
      await retryWebhookCallMutation({
        variables: {
          input: {
            webhook_call_id: webhookId,
          },
        },
      })
      notify({
        message: t('webhook_retry_success', { name }),
        notifyType: NotificationTypes.success,
      })
    } catch (error) {
      notifyError({
        message: t('error_retry_webhook_call'),
        error,
      })
    }
  }

  return { retryWebhook }
}
