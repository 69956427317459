import * as React from 'react'

const ShellyAvatar: React.FC = () => {
  return (
      <div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <rect width='24' height='24' rx='12' fill='white' />
          <rect width='24' height='24' rx='12' stroke='#E2E8F0' />
          <path
            d='M14.557 14.143L12.7171 15.1311V18.5641C14.631 18.3505 16.3371 17.2763 17.3933 15.6662C16.7925 15.3436 15.6684 14.7398 14.557 14.143Z'
            fill='#2563EB'
          />
          <path
            d='M5.98333 9.53842C5.36428 11.1061 5.36038 12.8859 5.98333 14.4617C7.80925 13.4812 7.53205 13.63 8.72686 12.9883V11.0118C6.62006 9.8806 7.31218 10.2522 5.98333 9.53842Z'
            fill='#2563EB'
          />
          <path
            d='M6.60716 8.33393C7.92702 9.04252 7.63757 8.88701 9.44337 9.85709L11.2834 8.86904V5.43604C9.43006 5.64322 7.69287 6.67553 6.60716 8.33393Z'
            fill='#2563EB'
          />
          <path
            d='M9.44331 14.143C7.53966 15.1653 8.19886 14.8113 6.60663 15.6666C7.63982 17.2417 9.33056 18.3461 11.2833 18.5641V15.1311L9.44331 14.143Z'
            fill='#2563EB'
          />
          <path
            d='M12 10.0238L10.16 11.0118V12.9883L12 13.9764L13.84 12.9883V11.0118L12 10.0238Z'
            fill='#2563EB'
          />
          <path
            d='M12.7165 5.43604V8.86904L14.5566 9.85709C16.0978 9.0292 15.6015 9.29572 17.3923 8.33393C16.358 6.75236 14.6594 5.65322 12.7165 5.43604Z'
            fill='#2563EB'
          />
          <path
            d='M18.0177 9.53842C16.4483 10.3814 17.4053 9.86756 15.2741 11.0118V12.9883C16.1189 13.4421 16.2858 13.5318 18.0177 14.4617C18.6425 12.881 18.6344 11.1003 18.0177 9.53842Z'
            fill='#2563EB'
          />
        </svg>
      </div>
  )
}

export default ShellyAvatar
