import { t } from 'i18next'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { useNotifications } from '../useNotifications'
import { useRetryAllFailedWebhooksMutation } from './types'

interface useGetWebhooksHook {
  retryFailedWebhookCalls: () => void
}

export const useRetryAllFailedWebhookCalls = (
  pathwayId: string,
): useGetWebhooksHook => {
  const { notifyError, notify } = useNotifications()
  
  const [retryWebhooksMutation] = useRetryAllFailedWebhooksMutation()

  const retryFailedWebhookCalls = async () => {
    try {
      await retryWebhooksMutation({
        variables: {
          input: { pathway_id: pathwayId },
        },
        refetchQueries: ['GetWebhooks'],
      })
      notify({
        message: t('webhook_retry_all_failed_success'),
        notifyType: NotificationTypes.success,
      })
    } catch (error) {
      notifyError({
        message: t('error_retry_all_failed_webhook_calls'),
        error,
      })
    }
  }

  return { retryFailedWebhookCalls }
}
