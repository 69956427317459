const workinglist = {
  column_stream_id: 'Care flow',
  column_step_title: 'Step',
  column_activity_title: 'Activity title',
  column_activity_date: 'Date',
  column_pathway_name: 'Pathway',
  search_placeholder: 'Search for stakeholder or activity name',
  see_pathway: 'See activity',
  column_patient: 'Name / Role',
  no_activities_found: 'No activities found',
}

export default workinglist
