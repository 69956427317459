import { Button } from '@awell/ui-kit/components/Button'
import { SearchRound, WarningRound } from '@awell/ui-kit/components/Icons'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { type Maybe } from '@awell/ui-kit/utils'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { isNil } from 'lodash'
import React, { useEffect, type FC, type ReactElement } from 'react'
import { t } from 'i18next'
import { type PatientProfile } from '../../types'
import { useStyles } from './useStyles'
import { environment } from '@awell/libs-web/environment'
import { useNavigate } from '@reach/router'

interface ImportingStepProps {
  loading: boolean
  patientProfiles: Array<Maybe<PatientProfile>> | undefined
  total: number | undefined
  queryId: string | undefined
  isImportSuccess: boolean | undefined
  onGoBack: () => void
  onSuccessfulImport: () => void
}

export const ImportingStep: FC<ImportingStepProps> = ({
  loading,
  patientProfiles,
  queryId,
  isImportSuccess,
  onGoBack,
  total,
  onSuccessfulImport,
}): ReactElement => {
  const classes = useStyles()
  
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !isNil(total) &&
      !isNil(patientProfiles) &&
      total > 0 &&
      patientProfiles.length > 0
    ) {
      onSuccessfulImport()
    }
  }, [total, patientProfiles])

  if (loading) {
    return (
      <div className={classes.importStatusContainer}>
        <Spinner />
        <Type variant={TypeVariants.regular} color={colors.neutralMid200}>
          {t('import_patient_loading')}
        </Type>
      </div>
    )
  }

  return (
    <div className={classes.importStatusContainer}>
      {total === 0 && isImportSuccess === true && (
        <>
          <IconWrapper className={classes.importPatientIcon} size='l'>
            <SearchRound />
          </IconWrapper>
          <Type
            variant={TypeVariants.h4}
            className={classes.importPatientTitle}
          >
            {t('import_patient_not_found')}
          </Type>
          <Type
            className={classes.importPatientTitleDescription}
            variant={TypeVariants.regular}
          >
            {t('import_patient_not_found_description')}
          </Type>
        </>
      )}
      {isImportSuccess === false && (
        <>
          <IconWrapper className={classes.importPatientIcon} size='l'>
            <WarningRound />
          </IconWrapper>
          <Type
            variant={TypeVariants.h4}
            className={classes.importPatientTitle}
          >
            {t('import_patient_something_went_wrong')}
          </Type>
          <Type
            className={classes.importPatientTitleDescription}
            variant={TypeVariants.regular}
          >
            {t('import_patient_something_went_wrong_description')}
          </Type>
        </>
      )}

      <Button
        className={classes.cancelButton}
        color='primary'
        onClick={onGoBack}
      >
        {t('try_again')}
      </Button>
      <Button
        onClick={() => {
          void navigate(
            `${environment.urls.careops}/developers/pdq-configuration/logs/${
              queryId ?? ''
            }`,
          )
        }}
      >
        {t('view_logs')}
      </Button>
    </div>
  )
}

ImportingStep.displayName = 'ImportingStep'
