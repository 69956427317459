import {
  AlertModal,
  type AlertModalLabels,
} from '@awell/ui-kit/components/AlertModal'
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { useNavigate } from '@reach/router'
import { isNil } from 'lodash'
import React, { type FC } from 'react'
import { t } from 'i18next'
import { useDeletePathway } from '../../hooks/useDeletePathway'

interface DeletePathwayModalProps {
  open: boolean
  onClose: () => void
  title: string
  pathway_id: string | undefined
  redirectToUri?: string | undefined
  patient_id?: string
}

export const DeletePathwayModal: FC<DeletePathwayModalProps> = ({
  open = false,
  onClose,
  title = '',
  pathway_id,
  redirectToUri,
  patient_id,
}) => {
  
  const { deletePathway } = useDeletePathway(patient_id)
  const navigate = useNavigate()
  const deletePathwayModalLabels: AlertModalLabels = {
    heading: t('delete_care_flow'),
    primaryAction: t('remove'),
    secondaryAction: t('cancel'),
  }

  const handleDeletePathway = async (): Promise<void> => {
    if (!isNil(pathway_id)) {
      await deletePathway({ pathway_id })
      onClose()
      if (!isNil(redirectToUri)) {
        void navigate(redirectToUri)
      }
    }
  }

  return (
    <AlertModal
      labels={deletePathwayModalLabels}
      open={open}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onAction={handleDeletePathway}
      onClose={onClose}
      appearance='danger'
    >
      <Type variant={TypeVariants.regular}>{title}</Type>
    </AlertModal>
  )
}
