import { t } from 'i18next'
import React, { useEffect, useState, type FC } from 'react'
import {
  SearchFilterBar,
  type FilterVariants,
} from '@awell/ui-kit/components/SearchFilterBar'
import { useWorkingListColumns } from './useWorkingListColumns'
import { useActivityTypes } from '../../hooks/useActivityTypes'
import { useActivityStatuses } from '../../hooks/useActivityStatuses'
import { useActivities } from '../../hooks/useActivities'
import {
  type Column,
  Table,
  type TableQuery,
  type TableQueryResult,
} from '@awell/ui-kit/components/Table'
import { FullPageListSkeleton } from '@awell/ui-kit/components/Skeleton/FullPageListSkeleton'
import {
  type ActivityObjectType,
  type Activity,
  ActivityStatus,
  PathwayStatus,
} from '../../generated/types-orchestration'
import { getQueryFilters } from './utils'
import { useDebouncedCallback } from 'use-debounce'
import { isNil, uniqBy } from 'lodash'
import { type OptionProps } from '@awell/ui-kit/components/ComboBox'
import { type StakeholderOption } from '../../hooks/useStakeholders/types'
import { usePathwayStatuses } from '../../hooks/usePathwayStatuses'

export interface WorkingListFilters {
  patient_id: string
  pathwayDefinitionIds: Array<string>
  activityTypes: Array<ActivityObjectType>
  activityStatuses: Array<ActivityStatus>
  stakeholders: Array<string>
  query: string
  pathwayStatuses: Array<PathwayStatus>
}

interface WorkingListTableProps {
  pathwayDefinitionId?: string
  patientId?: string
  stakeholders?: Array<StakeholderOption>
}

export const WorkingListTable: FC<WorkingListTableProps> = ({
  pathwayDefinitionId,
  patientId,
  stakeholders,
}) => {
  const workingListDefaultFilters: WorkingListFilters = {
    patient_id: patientId ?? '',
    pathwayDefinitionIds: !isNil(pathwayDefinitionId)
      ? [pathwayDefinitionId]
      : [],
    activityTypes: [],
    activityStatuses: [ActivityStatus.Active],
    stakeholders: [],
    query: '',
    pathwayStatuses: [PathwayStatus.Active],
  }

  const [filters, setFilters] = useState<WorkingListFilters>(
    workingListDefaultFilters,
  )

  
  const { columns } = useWorkingListColumns()
  const { activityTypes } = useActivityTypes({ includeSystemActivities: false })
  const { activityStatuses: allActivityStatuses } = useActivityStatuses()
  const { pathwayStatuses } = usePathwayStatuses()

  // Remove failed status. Why? The activities table shouldn't be used for monitoring but for operational work, instead the monitoring page should be used to look for failed activities.
  const activityStatuses = allActivityStatuses.filter(
    status => status.value !== ActivityStatus.Failed,
  )

  const { loading: loadingActivities, refetchActivities } = useActivities(
    getQueryFilters(workingListDefaultFilters),
  )

  const [stakeholderOptions, setStakeholderOptions] = useState<
    Array<OptionProps>
  >([])

  const tableRef = React.createRef<any>()
  const debouncedTableRefresh = useDebouncedCallback(
    () => tableRef?.current?.onQueryChange(),
    1000,
  )

  useEffect(() => {
    if (filters.stakeholders.length === 0 && !isNil(stakeholders)) {
      const allStakeholders = stakeholders
        .map(s => {
          // TODO: add support for other languages
          if (!isNil(s.label) && !isNil(s.label.en)) {
            return {
              label: s.label.en,
              value: s.label.en,
            }
          }
          return null
        })
        .filter(Boolean)

      const uniqueStakeholders = uniqBy(allStakeholders, 'label')

      setStakeholderOptions(uniqueStakeholders as Array<OptionProps>)
    }
  }, [stakeholders])

  if (loadingActivities) {
    return (
      <FullPageListSkeleton<Column<Activity>>
        hasHeaderButton={false}
        hasHeaderSubtitle={false}
        cellCount={columns.length}
        columns={columns}
      />
    )
  }
  const filterConfig: Array<FilterVariants> = [
    {
      label: t('activity_status'),
      name: 'activityStatuses',
      type: 'select',
      options: activityStatuses,
      // SearchFilterBar expects an array of option labels instead of values
      defaultChecked: ['To do'],
    },
    {
      label: t('activity_type'),
      name: 'activityTypes',
      type: 'select',
      options: activityTypes,
    },
    {
      label: t('pathway_status'),
      name: 'pathwayStatuses',
      type: 'select',
      options: pathwayStatuses,
      defaultChecked: [PathwayStatus.Active],
    },
  ]
  if (isNil(patientId)) {
    filterConfig.push({
      label: t('stakeholders'),
      name: 'stakeholders',
      type: 'select',
      options: stakeholderOptions,
    })
  }

  const handleFilters = ({
    stakeholders,
    activityStatuses,
    pathwayStatuses,
    activityTypes,
  }: WorkingListFilters): void => {
    setFilters({
      ...workingListDefaultFilters,
      stakeholders,
      activityStatuses,
      pathwayStatuses,
      activityTypes,
    })
    debouncedTableRefresh()
  }

  const handleQueryChange = async (
    query: TableQuery<Activity>,
  ): Promise<TableQueryResult<Activity>> => {
    const { page, pageSize } = query
    const offset = page * pageSize
    const count = pageSize
    const {
      data: {
        activities: { activities, pagination },
      },
    } = await refetchActivities({
      filters: getQueryFilters(filters),
      pagination: {
        count,
        offset,
      },
      sorting: {
        field: 'date',
        direction: 'desc',
      },
    })

    return {
      data: activities,
      totalCount: pagination?.total_count ?? 0,
      page,
    }
  }

  return (
    <>
      <SearchFilterBar<WorkingListFilters>
        onSearchFilter={handleFilters}
        placeholder={t('workinglist:search_placeholder')}
        filters={filterConfig}
        appliedFilters={filters ?? workingListDefaultFilters}
        hideFreeTextSearch
      />

      <Table
        tableRef={tableRef}
        rows={handleQueryChange}
        emptyDataSourceMessage={t('workinglist:no_activities_found')}
        columns={columns}
        paging
        fullWidth
        compactCells={false}
      />
    </>
  )
}
