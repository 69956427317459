import { Button } from '@awell/ui-kit/components/Button'
import { HeadingSecondary } from '@awell/ui-kit/components/Typography'
import React, { type FC, type ReactElement } from 'react'
import { t } from 'i18next'
import { Skeleton } from '@awell/ui-kit/components/Skeleton'
import { useStyles } from '../useStyles'

export const ActivePathwaysCardSkeleton: FC = (): ReactElement => {
  const classes = useStyles()
  

  return (
    <div className={classes.activePathwaysContainer}>
      <HeadingSecondary variant='subHeadline'>
        {t('active_pathways')}
      </HeadingSecondary>
      <div className={classes.activePathwaysList}>
        <div className={classes.pathwayRow}>
          <Skeleton variant='text' width='150px' />
          <Skeleton variant='text' width='50px' height='36px' />
        </div>
      </div>

      <Button disabled className={classes.cta} color='primary'>
        {t('view_all')}
      </Button>
    </div>
  )
}

ActivePathwaysCardSkeleton.displayName = 'ActivePathwaysCardSkeleton'
