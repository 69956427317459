import React, { type FC } from 'react'
import { isEmpty, isNil } from 'lodash'
import { Badge, Icon } from '@awell-health/design-system'

import {
  nonUserActivity,
  type ActivityContent,
  type ActivityEventType,
  type ActivityObjectType,
} from '../../../types'
import { formatDate } from '@awell/ui-kit/utils'

interface Props {
  content: ActivityContent
  events: Array<ActivityEventType>
  date: string
  stakeholder: string
  activityType: ActivityObjectType
}

export const Footer: FC<Props> = props => {
  const { content, events, date, stakeholder, activityType } = props

  const { activityStatus, activityStatusColor } = content

  const isUserActivity = !nonUserActivity.includes(activityType)

  const BadgeVariant = {
    red: 'error',
    grey: 'default',
    yellow: 'warning',
    blue: 'primary',
    green: 'success',
  }

  const completedBy = events.find(
    subActivity =>
      !['Shelly', 'ava', 'Unknown', 'Anonymous'].includes(
        subActivity.content.avatar,
      ),
  )

  const stakeholdeName = isNil(completedBy)
    ? stakeholder
    : completedBy.content.avatar

  const timestamp = formatDate({
    isoDateStr: date,
    dateFormat: 'dd MMM yyyy, h:mm:ss a', // 10 Jan 2025, 5:53:00 PM
  })

  return (
    <div className='flex items-center justify-between mt-4'>
      <div className='flex items-center gap-2'>
        {!isEmpty(activityStatus) && (
          <div className='mr-4 text-neutral-400 flex flex-row items-center'>
            <Badge
              variant={BadgeVariant[activityStatusColor]}
              size='md'
              className='font-bold'
            >
              {activityStatus}
            </Badge>
          </div>
        )}
        {!isNil(stakeholdeName) && isUserActivity && (
          <div className='mr-4 text-neutral-400 flex flex-row items-center'>
            <Icon icon='RiUserFill' size={16} className='fill-slate-600' />
            <div className='text-neutral-500 text-base ml-1'>
              {stakeholdeName}
            </div>
          </div>
        )}
      </div>
      <div className='flex gap-1 justify-end min-w-[180px]'>
        <Icon icon='RiTimeFill' size={16} className='fill-slate-600' />
        <div className='text-neutral-500 text-sm'>{timestamp}</div>
      </div>
    </div>
  )
}

Footer.displayName = 'Footer'
