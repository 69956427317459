import { isEmpty } from 'lodash'
import { type QuestionConfig } from '../../types'
import { type Maybe } from '../../../../utils'

// Helper function to validate the email format using regex
const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/
  return emailRegex.test(email)
}

export const validateEmailTypeQuestion = (
  questionConfig: Maybe<QuestionConfig> | undefined,
  value: string,
  labels: {
    name: string
  },
): boolean | string => {
  const inputRequired = questionConfig?.mandatory

  // Skip validation if input is not required and value is empty
  if (inputRequired === false && isEmpty(value)) {
    return true
  }

  // If the email is required but the value is empty
  if (inputRequired === true && isEmpty(value)) {
    return false
  }

  // Validate the email format using regex
  if (!isEmpty(value) && !isValidEmail(value)) {
    return labels.name
  }

  return true
}
