import { Button, IconButton } from '@awell/ui-kit/components/Button'
import { Dialog } from '@awell/ui-kit/components/Dialog'
import { AnimateSharedLayout } from 'framer-motion'
import React from 'react'
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core'
import { t } from 'i18next'
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { type PatientProfile } from '../../../types'
import { useStyles } from '../useStyles'

import {
  Cross,
  ChevronRight,
  ChevronLeft,
} from '@awell/ui-kit/components/Icons'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'
import { StepLabelLozenge } from '@awell/ui-kit/components/Lozenge'

interface CreateApiKeyModalProps {
  onClose: () => void
  onSelect: () => void
  onNext: () => void
  onPrevious: () => void
  open: boolean
  profile: PatientProfile
}

export const PreviewDetailsOnLitsModal = ({
  open,
  onClose,
  onSelect,
  onNext,
  onPrevious,
  profile,
}: CreateApiKeyModalProps): React.ReactElement => {
  
  const classes = useStyles()

  return (
    <AnimateSharedLayout>
      <Dialog
        className={classes.previewModal}
        open={open}
        maxWidth='sm'
        fullWidth
        keepMounted
        scroll='paper'
        onClose={onClose}
        aria-labelledby='create-new-api-key-modal'
      >
        <DialogTitle className={classes.previewModalPadding}>
          <div className={classes.previewHeaderContainer}>
            <div>
              <StepLabelLozenge content={t('patient')} />
            </div>
            <div className={classes.previewHeaderButtonsContainer}>
              <div className={classes.previewChevronContainer}>
                <IconButton
                  className={classes.previewHeaderIconButtons}
                  iconSize='m'
                  onClick={onPrevious}
                >
                  <IconWrapper
                    className={classes.previewHeaderRoundedButtons}
                    color={colors.neutralDark500}
                    background
                    rounded
                    size='m'
                  >
                    <ChevronLeft />
                  </IconWrapper>
                </IconButton>
                <IconButton
                  className={classes.previewHeaderIconButtons}
                  iconSize='m'
                  onClick={onNext}
                >
                  <IconWrapper
                    className={classes.previewHeaderRoundedButtons}
                    color={colors.neutralDark500}
                    background
                    rounded
                    size='m'
                  >
                    <ChevronRight />
                  </IconWrapper>
                </IconButton>
              </div>
              <IconButton
                className={classes.previewHeaderIconButtons}
                iconSize='m'
                onClick={onClose}
              >
                <IconWrapper
                  className={classes.previewHeaderRoundedButtons}
                  color={colors.neutralDark500}
                  background
                  rounded
                  size='m'
                >
                  <Cross />
                </IconWrapper>
              </IconButton>
            </div>
          </div>
          <Type variant={TypeVariants.h2}>
            {`${profile.first_name ?? ''} ${profile.last_name ?? ''}`}
          </Type>
          <Type variant={TypeVariants.medium} color={colors.neutralMid200}>
            {profile?.patient_code}
          </Type>
        </DialogTitle>
        <DialogContent className={classes.previewModalPadding}>
          <div>
            <Type variant={TypeVariants.h4}>{t('personal_information')}</Type>
            <div className={classes.profileDataBottom}>
              <div className={classes.previewDetailsColumn}>
                <Type variant={TypeVariants.h6}>{t('first_name')}</Type>
                <Type variant={TypeVariants.regular}>
                  {profile?.first_name}
                </Type>
              </div>
              <div className={classes.previewDetailsColumn}>
                <Type variant={TypeVariants.h6}>{t('family_name')}</Type>
                <Type variant={TypeVariants.regular}>{profile?.last_name}</Type>
              </div>
              <div className={classes.previewDetailsColumn}>
                <Type variant={TypeVariants.h6}>{t('sex')}</Type>
                <Type variant={TypeVariants.regular}>
                  {t(`${profile?.sex?.toLowerCase() ?? ''}`)}{' '}
                </Type>
              </div>
            </div>
            <div className={classes.profileDataBottom}>
              <div className={classes.previewDetailsColumn}>
                <Type variant={TypeVariants.h6}>{t('birth_date')}</Type>
                <Type variant={TypeVariants.regular}>
                  {profile?.birth_date}
                </Type>
              </div>
              <div className={classes.previewDetailsColumn}>
                <Type variant={TypeVariants.h6}>
                  {t('national_registry_number')}
                </Type>
                <Type variant={TypeVariants.regular}>
                  {profile?.national_registry_number}
                </Type>
              </div>
              <div className={classes.previewDetailsColumn}>
                <Type variant={TypeVariants.h6}>{t('language')}</Type>
                <Type variant={TypeVariants.regular}>
                  {profile?.preferred_language}
                </Type>
              </div>
            </div>

            <Type variant={TypeVariants.h4}>{t('address')}</Type>
            <div className={classes.profileDataBottom}>
              <div className={classes.previewDetailsColumn}>
                <Type variant={TypeVariants.regular}>
                  {profile?.address?.street}
                </Type>
                <Type variant={TypeVariants.regular}>{`${
                  profile?.address?.city ?? ''
                }, ${profile?.address?.zip ?? ''} `}</Type>
                <Type variant={TypeVariants.regular}>
                  {profile.address?.country}
                </Type>
              </div>
            </div>

            <Type variant={TypeVariants.h4}>{t('contact_information')}</Type>
            <div className={classes.profileDataBottom}>
              <div className={classes.previewDetailsColumn}>
                <Type variant={TypeVariants.h6}>{t('mobile_phone')}</Type>
                <Type variant={TypeVariants.regular}>
                  {profile?.mobile_phone}
                </Type>
              </div>
              <div className={classes.previewDetailsColumn}>
                <Type variant={TypeVariants.h6}>{t('phone')}</Type>
                <Type variant={TypeVariants.regular}>{profile?.phone}</Type>
              </div>
              <div className={classes.previewDetailsColumn}>
                <Type variant={TypeVariants.h6}>{t('email')}</Type>
                <Type variant={TypeVariants.regular}>{profile?.email}</Type>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.previewModalPadding}>
          <Button color='primary' onClick={onSelect}>
            {t('select_patient')}
          </Button>
        </DialogActions>
      </Dialog>
    </AnimateSharedLayout>
  )
}
