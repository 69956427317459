import React, { type FC } from 'react'

import { Button } from '@awell-health/design-system'

import { type ActivityContent, type Activity } from '../../../types'
import { createActionIcon } from '../../helperFunctions/getActivityIcon'
import { createActivityTitle } from '../../helperFunctions/getActivityTitle'

interface Props {
  onSelect: () => void
  activity: Activity
  content: ActivityContent
  completed: boolean
}

export const ActivityBody: FC<Props> = ({
  onSelect = () => null,
  activity,
  content,
  completed,
}) => {

  return (
    <div className='flex justify-between'>
      <div>
        <div className='flex gap-4'>
          <div>{createActionIcon(activity)}</div>

          <div className='flex flex-col'>
            <div className='text-neutral-700 text-xl font-medium'>
              {createActivityTitle(activity)}
            </div>
            <div className='text-neutral-400 text-base'>
              {content.trackName && `${content.trackName} / `}
              {content.stepName}
            </div>
          </div>
        </div>
      </div>
      <Button
        onClick={onSelect}
        variant={completed ? 'secondaryBlue' : 'primary'}
        className='min-w-[110px]'
      >
        {completed ? content.buttonTextComplete : content.buttonTextPending}
      </Button>
    </div>
  )
}

ActivityBody.displayName = 'ActivityBody'
