import { capitalize, uniqBy } from 'lodash'
import React, { type FC } from 'react'
import { t } from 'i18next'

import {
  Checkbox,
  Select,
  type SelectValue,
  type SelectItem,
  DateRangeSelect,
} from '@awell-health/design-system'

import { useStakeholdersByPathwayDefinitionIds } from '../../../hooks/useStakeholders'
import { useCareflowActivityTypes } from '../../../hooks/useCareflowActivities/useCareflowActivityTypes'
import {
  type ActivityObjectType,
  DefaultActivityTypeOptions,
  systemActivities,
  ActivityStatus,
  type ActivitiesListFilters,
} from '../types'

export interface Props {
  pathwayDefinitionId: string
  pathwayId: string
  handleFilterChange: (
    filterKey: keyof ActivitiesListFilters,
    value: SelectValue | boolean,
  ) => void
  handleDateRangeSelect: (from: string, to: string) => void
  loading: boolean
  activityFilters: ActivitiesListFilters
}

export const CareflowActivityFilters: FC<Props> = props => {
  const {
    pathwayDefinitionId,
    pathwayId,
    handleFilterChange,
    loading,
    activityFilters,
    handleDateRangeSelect,
  } = props

  

  const statusDictionary = {
    [ActivityStatus.Active]: t('activityfeed:to_do'),
    [ActivityStatus.Done]: t('activityfeed:done'),
    [ActivityStatus.Canceled]: capitalize(t('activityfeed:canceled')),
    [ActivityStatus.Failed]: capitalize(t('activityfeed:failed')),
    [ActivityStatus.Expired]: capitalize(t('activityfeed:expired')),
    in_progress: capitalize(t('activityfeed:in_progress')),
  }

  const { stakeholders } = useStakeholdersByPathwayDefinitionIds({
    pathwayDefinitionIds: [pathwayDefinitionId],
  })

  const { loading: activityTypesLoading, activityTypes } =
    useCareflowActivityTypes(pathwayId)

  const getActivityTypeOptions = (
    activityTypes: Array<string>,
  ): Array<SelectItem> => {
    return activityTypes.length > 0
      ? activityTypes
          .filter(
            type =>
              !systemActivities.includes(
                type.toUpperCase() as ActivityObjectType,
              ),
          )
          .map(type => ({
            value: type.toUpperCase(),
            label:
              DefaultActivityTypeOptions.find(
                opt => opt.value === type.toUpperCase(),
              )?.label ?? capitalize(type.toLowerCase()),
          }))
      : DefaultActivityTypeOptions
  }

  const activityTypeOptions = getActivityTypeOptions(activityTypes)

  // excluding cancelled activities from the list as we filter them out in the DB query
  const activityStatuses = Object.values(ActivityStatus)
    .filter(status => status !== ActivityStatus.Canceled)
    .map(activityStatus => {
      return {
        value: activityStatus,
        label: capitalize(statusDictionary[activityStatus]),
      }
    })

  const getStakeholderOptions = (
    stakeholders?: Array<{ label: { en: string }; id: string }>,
  ): Array<SelectItem> => {
    return uniqBy(
      stakeholders?.map(stakeholder => ({
        label: stakeholder.label.en,
        value: stakeholder.label.en,
      })),
      'value',
    )
  }

  return (
    <div className='sticky top-0 z-10 p-2'>
      <div className='flex gap-3'>
        <DateRangeSelect
          onSelect={handleDateRangeSelect}
          defaultDateRange={activityFilters.date_range}
        />
        <div className='min-w-[200px]'>
          <Select
            key='activity_status'
            isSearchable={true}
            isMulti={true}
            placeholder={t('activityfeed:select_status')}
            options={activityStatuses}
            onChange={value => handleFilterChange('activity_status', value)}
            value={activityFilters.activity_status.map(status => ({
              label: statusDictionary[status],
              value: status,
            }))}
            disabled={loading}
            isClearable
          />
        </div>
        <div className='min-w-[200px]'>
          <Select
            key='activity_type'
            isSearchable={true}
            isMulti={true}
            placeholder={t('activityfeed:select_activity_type')}
            options={activityTypeOptions}
            onChange={value => handleFilterChange('activity_type', value)}
            value={activityFilters.activity_type.map(type => ({
              label:
                activityTypeOptions.find(option => option.value === type)
                  ?.label ?? '',
              value: type,
            }))}
            disabled={loading || activityTypesLoading}
            isClearable
          />
        </div>
        <div className='min-w-[200px]'>
          <Select
            key='stakeholder'
            isSearchable={true}
            isMulti={true}
            placeholder={t('activityfeed:select_stakeholder')}
            options={getStakeholderOptions(stakeholders)}
            onChange={value => handleFilterChange('stakeholders', value)}
            value={activityFilters.stakeholders.map(stakeholder => ({
              label: stakeholder,
              value: stakeholder,
            }))}
            disabled={loading}
            isClearable
          />
        </div>
        <div className='flex items-center'>
          <Checkbox
            label={t('activityfeed:show_system_activities')}
            onChange={() =>
              handleFilterChange(
                'hide_system_activities',
                !activityFilters.hide_system_activities,
              )
            }
            checked={!activityFilters.hide_system_activities}
            type='checkbox'
            inputSize='md'
          />
        </div>
      </div>
    </div>
  )
}

CareflowActivityFilters.displayName = 'CareflowActivityFilters'
