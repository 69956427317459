import React, { type FC } from 'react'
import { t } from 'i18next'
import ReactJson from 'react-json-view'
import { type RouteComponentProps, useParams } from '@reach/router'
import { ContentContainer } from '@awell/ui-kit/components/ContentContainer'
import { NavigationButton } from '@awell/ui-kit/components/NavigationButton'
import {
  HeadingMain,
  SubHeading,
  Text,
} from '@awell/ui-kit/components/Typography/TypographyNew'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { ScrollContainer } from '@awell/ui-kit/components/ScrollContainer'
import { Table } from '@awell/ui-kit/components/Table'
import { format } from 'date-fns'
import { Button } from '@awell/ui-kit/components/Button'
import { useStyles } from './useStyles'
import { useWebhooksPreviewListColumns } from './useWebhookDetailsListColumns'
import { WebhookDetailsSkeleton } from './skeleton'
import { useRetryWebhookCall } from '../../../hooks/useRetryWebhookCall'
import { useGetPathwayWebhookCall } from '../../../hooks/useGetPathwayWebhookCall'
import type { WebhookEventType, WebhookStatus } from '../types'

interface WebhookDetailsProps extends RouteComponentProps {
  pathwayId: string
}

export const WebhookDetails: FC<WebhookDetailsProps> = ({ pathwayId }) => {
  const { id } = useParams()
  const { loading, webhookCall } = useGetPathwayWebhookCall(id as string)
  
  const { columns } = useWebhooksPreviewListColumns()
  const { retryWebhook } = useRetryWebhookCall()

  const classes = useStyles()
  const backNavButtonUrl = `/pathway/${pathwayId}/logs/webhooks`
  if (loading || !webhookCall) {
    return <WebhookDetailsSkeleton backNavigationList={backNavButtonUrl} />
  }
  const triggeredAtLastResponse =
    webhookCall.responses[webhookCall.responses.length - 1].date

    const getWebhookEventTypeKey = (eventType: WebhookEventType) => {
      return `webhook.${eventType}` as const
    }
    const getWebhookStatusKey = (status: WebhookStatus) => {
      return `WEBHOOK_${status}` as const
    }

  return (
    <ScrollContainer className={classes.container}>
      <ContentContainer>
        <article>
          <div className={classes.navContainer}>
            <div style={{ display: 'flex' }}>
              <NavigationButton linkUrl={backNavButtonUrl} />
              <div className={classes.textContainer}>
                <HeadingMain
                  variant='subHeadline'
                  color={colors.neutralDark500}
                >
                  {webhookCall.webhook_name}
                </HeadingMain>
              </div>
            </div>
            <Button
              color='primary'
              onClick={() => {
                retryWebhook(id as string, webhookCall.webhook_name)
              }}
            >
              {t('retry')}
            </Button>
          </div>

          <div className={classes.basicInfoContainer}>
            <div className={classes.basicInfoGroup}>
              <Text variant='tinyHeadlineCaps' color={colors.neutralMid200}>
                {t('URL')}
              </Text>
              <Text>{webhookCall.request.endpoint}</Text>
            </div>

            <div className={classes.basicInfoGroup}>
              <Text variant='tinyHeadlineCaps' color={colors.neutralMid200}>
                {t('triggered_at')}
              </Text>
              <Text>
                {format(
                  new Date(triggeredAtLastResponse),
                  'd-LLL-yyyy K:mm:ss a',
                )}
              </Text>
            </div>

            <div className={classes.basicInfoGroup}>
              <Text variant='tinyHeadlineCaps' color={colors.neutralMid200}>
                {t('trigger_event')}
              </Text>
              <Text>{t(getWebhookEventTypeKey(webhookCall.event_type as WebhookEventType))}</Text>
            </div>
            <div className={classes.basicInfoGroup}>
              <Text variant='tinyHeadlineCaps' color={colors.neutralMid200}>
                {t('status')}
              </Text>
              <Text>{t(getWebhookStatusKey(webhookCall.status as WebhookStatus))}</Text>
            </div>
          </div>

          <section>
            <SubHeading variant='smallHeadline'>{t('logs')}</SubHeading>
            <Table columns={columns} rows={webhookCall.responses} fullWidth />
          </section>

          <section className={classes.bodyContainer}>
            <div>
              <SubHeading variant='smallHeadline'>
                {t('request_body')}
              </SubHeading>
              <div className={classes.jsonContainer}>
                <ReactJson src={JSON.parse(webhookCall.request.body)} />
              </div>
            </div>
          </section>
        </article>
      </ContentContainer>
    </ScrollContainer>
  )
}

WebhookDetails.displayName = 'WebhookDetails'
