import {
  TableRow,
  TableCell,
  Icon,
  Button,
  Badge,
  Tooltip,
} from '@awell-health/design-system'
import { navigate } from '@reach/router'
import React, { type FC } from 'react'
import { type Patient } from '../types'
import { LinkCell } from '@awell/ui-kit/components/Table/LinkCell'
import { defaultProfilePropTo, formatDate } from '../../../utils'
import { t } from 'i18next'
import { orderBy } from 'lodash'

interface PatientTableRowProps {
  patient: Patient
}

export const PathwayTableRow: FC<PatientTableRowProps> = ({ patient }) => {
  

  // create the badges and sort the identifiers by the system to ensure they all display in the same order
  const Identifiers = (): JSX.Element => {
    const identifiers = patient?.profile?.identifier ?? []
    if (identifiers.length > 0) {
      return (
        <div className='flex flex-wrap max-w-[230px]'>
          {orderBy(identifiers, ['system']).map((val, index) => {
            return (
              <Tooltip
                datatip={val.system}
                placement='top'
                variant='default'
                key={index}
              >
                <Badge
                  key={index}
                  size='sm'
                  variant='primary'
                  className='mr-2 mb-2'
                >
                  {val.value}
                </Badge>
              </Tooltip>
            )
          })}
        </div>
      )
    } else {
      return <div>-</div>
    }
  }

  return (
    <TableRow className='break-word'>
      <TableCell>
        <LinkCell
          title={defaultProfilePropTo({
            prop: 'name',
            defaultValue: t('anonymous'),
          })(patient)}
          id={patient.id}
        />
      </TableCell>
      <TableCell>
        <Identifiers />
      </TableCell>
      <TableCell>
        {defaultProfilePropTo({ prop: 'patient_code' })(patient)}
      </TableCell>
      <TableCell>
        {defaultProfilePropTo({
          prop: 'birth_date',
          formatFn: formatDate('dd/MM/yyyy'),
        })(patient)}
      </TableCell>
      <TableCell>{defaultProfilePropTo({ prop: 'email' })(patient)}</TableCell>
      <TableCell>
        {defaultProfilePropTo({ prop: 'preferred_language' })(patient)}
      </TableCell>
      <TableCell>
        <Tooltip
          datatip={t('view_activities')}
          placement='right'
          variant='default'
        >
          <Button
            onClick={() => {
              void navigate(`/activities/patient/${patient.id}`)
            }}
            shape='squareLg'
            size='sm'
            variant='ghost'
          >
            <Icon icon='RiSearchLine' size={20} />
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}
