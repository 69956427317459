import { capitalize } from 'lodash'
import { t } from 'i18next'
import { PathwayStatus } from '../usePathway/types'

interface UsePathwayStatusesHook {
  pathwayStatuses: Array<{
    value: PathwayStatus
    label: string
  }>
}

export const usePathwayStatuses = (): UsePathwayStatusesHook => {
  

  const labelDictionary = {
    [PathwayStatus.Active]: t('activityfeed:active'),
    [PathwayStatus.Completed]: t('activityfeed:completed'),
    [PathwayStatus.Stopped]: t('activityfeed:stopped'),
  }

  const pathwayStatuses = Object.keys(labelDictionary).map(key => {
    return {
      value: key as PathwayStatus,
      // @ts-expect-error - index is string
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      label: capitalize(labelDictionary[key]),
    }
  })

  return {
    pathwayStatuses,
  }
}
