import { t } from 'i18next'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { useNotifications } from '../useNotifications'
import { useRetryAllApiCallsMutation } from './types'

interface useRetryAllApiCallsHook {
  retryAllApiCalls: (id: string) => Promise<void>
}

export const useRetryAllApiCalls = (): useRetryAllApiCallsHook => {
  const { notifyError, notify } = useNotifications()
  
  const [retryAllApiCallsMutation] = useRetryAllApiCallsMutation()

  const retryAllApiCalls = async (pathwayId: string) => {
    try {
      await retryAllApiCallsMutation({
        variables: {
          input: {
            pathway_id: pathwayId,
          },
        },
      })
      notify({
        message: t('api_call_retry_success'),
        notifyType: NotificationTypes.success,
      })
    } catch (error) {
      notifyError({
        message: t('api_call_retry_error'),
        error,
      })
    }
  }

  return { retryAllApiCalls }
}
