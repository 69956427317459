import {
  AlertModal,
  type AlertModalLabels,
} from '@awell/ui-kit/components/AlertModal'
import { InputField } from '@awell/ui-kit/components/InputField'
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { isNil } from 'lodash'
import React, { useState, type FC } from 'react'
import { t } from 'i18next'
import { useStopPathway } from '../../hooks/useStopPathway'
import { useStyles } from './useStyles'

interface StopPathwayModalProps {
  open: boolean
  onClose: () => void
  title: string
  pathway_id: string | undefined
}

export const StopPathwayModal: FC<StopPathwayModalProps> = ({
  open = false,
  onClose,
  title = '',
  pathway_id,
}) => {
  
  const { stopPathway } = useStopPathway()
  const [reasonToStopPathway, setReasonToStopPathway] = useState<string>()
  const stopPathwayModalLabels: AlertModalLabels = {
    heading: t('stop_pathway'),
    primaryAction: t('stop_pathway_confirmation_button'),
    secondaryAction: t('cancel'),
  }

  const classes = useStyles()

  const handleStopPathway = async (): Promise<void> => {
    if (!isNil(pathway_id)) {
      await stopPathway({
        pathway_id,
        reason: reasonToStopPathway,
      })
      handleCloseModal()
    }
  }

  const handleCloseModal = (): void => {
    setReasonToStopPathway(undefined)
    onClose()
  }

  return (
    <AlertModal
      labels={stopPathwayModalLabels}
      open={open}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onAction={handleStopPathway}
      onClose={handleCloseModal}
      appearance='danger'
    >
      <Type variant={TypeVariants.regular}>{title}</Type>
      <div className={classes.container}>
        <Type variant={TypeVariants.medium}>
          <strong>{t('stop_patient_reason_heading')}</strong>
        </Type>
        <InputField
          type={'text'}
          compact
          withErrorSpace
          required={false}
          value={reasonToStopPathway ?? ''}
          placeholder={t('stop_patient_reason_hint')}
          onChange={setReasonToStopPathway}
        />
      </div>
    </AlertModal>
  )
}
