import { environment } from '@awell/libs-web/environment'
import { CalculationPreview } from '@awell/ui-kit/components/CalculationPreview'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { colors } from '@awell/ui-kit/utils/style-guide'
import React, { type FC } from 'react'
import { t } from 'i18next'
import { roundToDecimalPlaces } from '@awell/ui-kit/utils'
import { isNil, last } from 'lodash'
import type { Activity } from '../../hooks/useCalculationActivity'
import { ActivityAction } from './types'
import { useCalculationActivity } from '../../hooks/useCalculationActivity'
import { useStyles } from './useStyles'

interface CalculationActivityDetailsProps {
  activity: Activity
}

export const CalculationActivityDetails: FC<CalculationActivityDetailsProps> = ({
  activity,
}) => {
  const classes = useStyles()
  
  const { loading, results = [], calculationId } = useCalculationActivity({
    activity,
  })

  if (loading) {
    return <Spinner />
  }

  const resultItems = results.map(({ subresult_id, value, unit, status }) => {
    const MAX_DECIMAL_PLACES = 2
    const displayValue = roundToDecimalPlaces(value, MAX_DECIMAL_PLACES)

    return (
      <div className={classes.calculationData} key={subresult_id}>
        <Type variant={TypeVariants.small} color={colors.neutralLight100}>
          {subresult_id}
        </Type>
        {status === 'CALCULATED' ? (
          <div key={subresult_id}>
            <Type variant={TypeVariants.h5}>
              {`${displayValue}`} {unit}
            </Type>
          </div>
        ) : (
          <div key={subresult_id}>
            <Type variant={TypeVariants.subtle} color={colors.neutralLight100}>
              {status}
            </Type>
          </div>
        )}
      </div>
    )
  })

  const subActivity = last(activity?.sub_activities)

  const isJsonParsable = (string: string) => {
    if (isNil(string)) {
      return false
    }
    try {
      JSON.parse(string)
    } catch (e) {
      return false
    }
    return true
  }

  const activityDisclaimerText = t('calculation_disclaimer')

  const calculationOutcome = (
    <>
      {subActivity?.action === ActivityAction.Computed && (
        <div className={classes.calculationDisclaimer}>
          <div>
            <Type variant={TypeVariants.regular}>{activityDisclaimerText}</Type>
            <div className={classes.calculationDocumentation}>
              <Type variant={TypeVariants.h6}>
                {t('calculation_documentation_label')}
              </Type>
              <a
                href={`${environment.urls.calculations_explorer}/${calculationId}/documentation`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('view_calculation_documentation')}
              </a>
            </div>
          </div>
        </div>
      )}
      {subActivity?.action === ActivityAction.Failed && (
        <div className={classes.calculationDisclaimer}>
          <Type variant={TypeVariants.regular} color={colors.red}>
            {t('calculation_results_error')}
          </Type>
          {t('error_message')}
          {': '}
          <Type span fitContent variant={TypeVariants.medium}>
            {isJsonParsable(subActivity?.error ?? '')
              ? JSON.parse(subActivity?.error ?? '')?.error?.message
              : subActivity?.error}
          </Type>
        </div>
      )}
    </>
  )

  return (
    <CalculationPreview
      calculationOutcome={calculationOutcome}
      resultItems={resultItems}
    />
  )
}
