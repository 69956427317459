import { makeStyles } from '@material-ui/core'
import { colors, rounded, shadows, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    transform: `translateX(${spacing.xs})`,
  },
  // Copy styling from activity feed card
  card: {
    ...shadows.xs,
    ...rounded.m,
    padding: spacing.s,
    backgroundColor: colors.neutralLight0,
    border: `1px solid ${colors.neutralLight30}`,
    width: '500px'
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    minHeight: spacing.l,
  },
  iconWrapper: {
    '& > *': {
      width: 'fit-content',
      marginRight: spacing.xxs,
    },
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  extra: {
    paddingTop: spacing.xxs,
    paddingBottom: spacing.xxs,
    display: 'flex',
    justifyContent: 'space-between',
  },
})
