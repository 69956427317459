import React, { type ReactElement, type FC, useEffect } from 'react'
import { t } from 'i18next'
import { type RouteComponentProps, useNavigate } from '@reach/router'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { Button } from '@awell/ui-kit/components/Button'
import classnames from 'classnames'
import { useFieldArray, useForm } from 'react-hook-form'
import { useFormStyles } from '@awell/ui-kit/components/Form'
import { FormBlock } from '../../../components/FormBlock'
import {
  Identifiers,
  PatientProfileInput,
  PatientProfileRadioGroup,
  PatientProfileSelect,
} from '../FormInputs'
import { useStyles } from '../useStyles'
import {
  initialPatientProfile,
  useCreatePatient,
} from '../../../hooks/useCreatePatient'
import { sexOptions, languageOptions, type IdentifierField } from '../types'
import { isNil } from 'lodash'
import { usePatientDemographicsConfiguration } from '../../../hooks/usePatientDemographicConfiguration'
import { HeadingMain } from '@awell/ui-kit/components/Typography'
import { useIdentifierSystems } from '../../../hooks/useIdentifierSystems'
import { Divider } from '@material-ui/core'
import { spacing } from '@awell/ui-kit/utils/style-guide'

export const CreatePatient: FC<RouteComponentProps> = (): ReactElement => {
  const { addBreadcrumbs } = useBreadcrumbs()
  const {
    createPatientFormContainer,
    buttonsContainer,
    cancelButton,
    createPatientHeader,
  } = useStyles()
  const { inputFieldRow, twoInputs, oneInputHalfRow, oneInputFullRow } =
    useFormStyles()
  
  const navigate = useNavigate()

  const { identifierSystemsOptions } = useIdentifierSystems()
  const { control, errors, handleSubmit, reset } = useForm({
    defaultValues: initialPatientProfile,
  })
  const { fields, append, remove } = useFieldArray({
    name: 'identifier',
    control,
  })
  const { createPatient } = useCreatePatient()
  const breadcrumb = {
    label: t('create_patient'),
    url: '/patients/create',
    level: 1,
    isToShowTitle: false,
  }
  const { is_enabled: isImportAvailable } =
    usePatientDemographicsConfiguration()

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])

  const onSubmit = async (): Promise<void> => {
    await handleSubmit(async data => {
      const patient = await createPatient(data)
      if (!isNil(patient)) {
        await navigate(`/patients/${patient.id}`)
      }
    })()
  }
  const onReset = (): void => {
    reset(initialPatientProfile)
  }

  return (
    <>
      <div className={createPatientFormContainer}>
        <div className={createPatientHeader}>
          <HeadingMain variant='headline'>{t('create_patient')}</HeadingMain>
          {isImportAvailable && (
            <div>
              <Button
                onClick={() => {
                  void navigate('/patients/import')
                }}
                color='primary'
              >
                {t('import_patient')}
              </Button>
            </div>
          )}
        </div>

        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormBlock
            title={t('identifiers')}
            subtitleLink='https://developers.awellhealth.com/awell-orchestration/docs/misc/patient-identifiers'
            subtitleLinkText={t('patient_code_learn_more')}
          >
            <Identifiers
              fields={fields as Array<IdentifierField>}
              onAppend={append}
              onRemove={remove}
              addFieldTitle={t('add_identifier')}
              control={control}
              errors={errors}
              identifierSystems={identifierSystemsOptions}
            />
            <Divider
              style={{ marginTop: spacing.xs, marginBottom: spacing.xs }}
            />
            <div className={classnames(inputFieldRow, oneInputHalfRow)}>
              <PatientProfileInput
                name='patient_code'
                control={control}
                errors={errors}
                label={t('patient_code_deprecated')}
                description={t('patient_code_description')}
                placeholder='011010000110110000001010'
              />
            </div>
          </FormBlock>
          <FormBlock title={t('personal_information')}>
            <div className={classnames(inputFieldRow, twoInputs)}>
              <PatientProfileInput
                name='first_name'
                control={control}
                errors={errors}
                label={t('first_name')}
                placeholder='Henrietta'
              />
              <PatientProfileInput
                name='last_name'
                control={control}
                errors={errors}
                label={t('family_name')}
                placeholder='Lacks'
              />
            </div>
            <div className={classnames(inputFieldRow, oneInputHalfRow)}>
              <PatientProfileInput
                name='birth_date'
                control={control}
                errors={errors}
                label={t('birth_date')}
                inputType='date'
              />
            </div>
            <div className={inputFieldRow}>
              <PatientProfileRadioGroup
                name='sex'
                control={control}
                errors={errors}
                options={sexOptions}
                label={t('sex')}
              />
            </div>
            <div className={classnames(inputFieldRow, oneInputFullRow)}>
              <PatientProfileInput
                name='address.street'
                control={control}
                errors={errors}
                placeholder='1 Royal Fort House'
                label={t('street_address')}
              />
            </div>
            <div className={classnames(inputFieldRow, twoInputs)}>
              <PatientProfileInput
                name='address.city'
                control={control}
                errors={errors}
                placeholder='Bristol'
                label={t('city')}
              />
              <PatientProfileInput
                name='address.state'
                control={control}
                errors={errors}
                placeholder='South West'
                label={t('province')}
              />
            </div>
            <div className={classnames(inputFieldRow, twoInputs)}>
              <PatientProfileInput
                name='address.zip'
                control={control}
                errors={errors}
                label={t('postal_code')}
                placeholder='BS8 1TH'
              />
              <PatientProfileInput
                name='address.country'
                control={control}
                errors={errors}
                label={t('country')}
                placeholder='United Kingdom'
              />
            </div>
            <div className={classnames(inputFieldRow, oneInputFullRow)}>
              <PatientProfileInput
                name='national_registry_number'
                control={control}
                errors={errors}
                label={t('national_registry_number')}
                placeholder='HeLa1920'
              />
            </div>
            <div className={classnames(inputFieldRow, oneInputHalfRow)}>
              <PatientProfileSelect
                name='preferred_language'
                control={control}
                errors={errors}
                options={languageOptions}
                label={t('language')}
              />
            </div>
          </FormBlock>
          <FormBlock title={t('contact_information')}>
            <div className={classnames(inputFieldRow, twoInputs)}>
              <PatientProfileInput
                name='email'
                control={control}
                errors={errors}
                label={t('email')}
                inputType='email'
                placeholder='h.lacks@hela.org'
                errorText={t('form_email_error_text')}
              />
              <PatientProfileInput
                name='mobile_phone'
                control={control}
                errors={errors}
                label={t('mobile_phone')}
                placeholder='+324562919700'
                inputType='tel'
              />
            </div>
            <div className={classnames(inputFieldRow, oneInputHalfRow)}>
              <PatientProfileInput
                name='phone'
                control={control}
                errors={errors}
                label={t('phone')}
                placeholder='+3225249797'
                inputType='tel'
              />
            </div>
          </FormBlock>

          <div className={buttonsContainer}>
            <Button onClick={onReset} variant='text'>
              <>{t('reset_form')}</>
            </Button>
            <div>
              <Button
                color='primary'
                className={cancelButton}
                onClick={() => {
                  void navigate(-1)
                }}
              >
                <>{t('cancel')}</>
              </Button>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <Button onClick={onSubmit}>
                <>{t('create_patient')}</>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

CreatePatient.displayName = 'CreatePatient'
