import React, { type ReactElement } from 'react'
import { t } from 'i18next'
import { CrashReporter } from '../../crash-reporter'
import {
  type NotifyErrorOptions,
  type NotifyError,
  type Notify,
} from '@awell/libs-web/src/hooks/useNotifications/types'
import { useNotifications as useNotificationsWeb } from '@awell/libs-web/hooks/useNotifications'
import { Button } from '@awell-health/design-system'

interface UseNotificationHook {
  notify: Notify
  notifyError: NotifyError
  notifyDelete: Notify
  notifySuccess: Notify
  notifyInformative: Notify
}

export const useNotifications = (): UseNotificationHook => {
  const {
    notify,
    notifyError: notifyErrorWeb,
    notifyInformative,
    notifyDelete,
    notifySuccess,
  } = useNotificationsWeb()

  const notifyError = ({ error, message }: NotifyErrorOptions): void => {
    if (error !== undefined && error instanceof Error) {
      const ErrorActions = (): ReactElement => {
        const handleReport = (): void => {
          notifyInformative({
            message: t('error_reported'),
          })

          CrashReporter.report(error)
        }

        return (
          <Button
            variant='secondary'
            onClick={handleReport}
            data-heap='report-error'
          >
            {t('report_error')}
          </Button>
        )
      }

      notifyErrorWeb({
        message,
        error,
        persist: true,
        actions: <ErrorActions />,
        closeOnClick: true,
      })
    }
  }

  return { notifyError, notify, notifyDelete, notifySuccess, notifyInformative }
}
