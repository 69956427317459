import { t } from 'i18next'
import type { ChecklistItem } from '@awell/ui-kit/components/ChecklistPreview'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { useState } from 'react'
import { useNotifications } from '../useNotifications'
import type { Activity } from './types'
import { useSubmitChecklistMutation, useGetChecklistQuery } from './types'

interface UseChecklistActivityHook {
  loading: boolean
  disabled: boolean
  onSubmit: () => Promise<void>
  title: string
  items: Array<ChecklistItem>
}

export const useChecklistActivity = ({
  activity,
}: {
  activity: Activity
}): UseChecklistActivityHook => {
  const {
    id: activity_id,
    object: { id: checklist_id },
  } = activity

  
  const { notify, notifyError } = useNotifications()
  const [submitChecklist] = useSubmitChecklistMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { data, loading, error } = useGetChecklistQuery({
    variables: {
      id: checklist_id,
    },
  })

  if (error) {
    notifyError({
      error,
      message: t('checklist_load_error_notification'),
    })
  }

  const onSubmit = async () => {
    setIsSubmitting(true)
    try {
      await submitChecklist({
        variables: {
          input: {
            activity_id,
          },
        },
      })
      notify({
        message: t('checklist_success_notification'),
        notifyType: NotificationTypes.success,
      })
    } catch (error) {
      setIsSubmitting(false)
      notifyError({
        error,
        message: t('checklist_submit_error_notification'),
      })
    }
  }

  if (loading) {
    return {
      loading,
      onSubmit,
      title: '',
      items: [],
      disabled: true,
    }
  }

  const { title = '', items = [] } = data?.checklist?.checklist ?? {}

  const formatted_items = items.map((item, index) => {
    return { id: index.toString(), label: item }
  })

  return {
    loading,
    onSubmit,
    title,
    items: formatted_items,
    disabled: isSubmitting,
  }
}
