import React, { type FC } from 'react'
import { t } from 'i18next'
import { TableSkeleton } from '@awell/ui-kit/components/Skeleton/TableSkeleton'
import { FormBlock } from '../../../../components/FormBlock'
import { usePatientPathwayListColumns } from '../usePatientPathwayListColumns'

export const PatientPathwaysTabSkeleton: FC = () => {
  
  const { columnsForActivePathways } = usePatientPathwayListColumns({
    onDeletePathway: () => null,
    onStopPathway: () => null,
  })
  return (
    <>
      <FormBlock title={t('active_pathways')}>
        <TableSkeleton
          cellCount={1}
          columns={columnsForActivePathways}
          rowsCount={4}
        />
      </FormBlock>
      <FormBlock title={t('completed_pathways')}>
        <TableSkeleton
          cellCount={1}
          rowsCount={4}
          columns={columnsForActivePathways}
        />
      </FormBlock>
      <FormBlock title={t('stopped_pathways')}>
        <TableSkeleton
          cellCount={1}
          rowsCount={4}
          columns={columnsForActivePathways}
        />
      </FormBlock>
    </>
  )
}
