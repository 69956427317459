/* eslint-disable @typescript-eslint/no-misused-promises */
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import React, { type FormEventHandler, type FC, type ReactElement } from 'react'
import { t } from 'i18next'
import { useStyles } from '../useStyles'

import { Button } from '@awell/ui-kit/components/Button'
import {
  type PatientProfile,
  languageOptions,
  sexOptions,
} from '../../../types'
import { FormBlock } from '../../../../../components/FormBlock'
import { useFormStyles } from '@awell/ui-kit/components/Form'
import classnames from 'classnames'

import {
  PatientProfileInput,
  PatientProfileRadioGroup,
  PatientProfileSelect,
} from '../../../FormInputs'
import { type DeepMap, type FieldError, type Control } from 'react-hook-form'

interface ShowCreatedPatientAndCTAProps {
  patientIdentifier: string
  control: Control<PatientProfile>
  errors: DeepMap<PatientProfile, FieldError>
  handleSubmit: (data: any) => FormEventHandler<HTMLFormElement>
  onSubmit: () => Promise<void>
  goBack: () => void
}

export const ImportAndCreatePatientForm: FC<ShowCreatedPatientAndCTAProps> = ({
  patientIdentifier,
  control,
  errors,
  handleSubmit,
  onSubmit,
  goBack,
}): ReactElement => {
  const classes = useStyles()
  
  const { inputFieldRow, twoInputs, oneInputHalfRow, oneInputFullRow } =
    useFormStyles()

  return (
    <div className={classes.createPatientStepContainer}>
      <Type variant={TypeVariants.h4} className={classes.importPatientTitle}>
        {t('import_patient_successfully_imported', { patientIdentifier })}
      </Type>
      <Type
        className={classes.importPatientTitleDescription}
        variant={TypeVariants.regular}
      >
        {t('import_patient_successfully_imported_description')}
      </Type>

      <div className={classes.createPatientFormContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormBlock title={t('identification')}>
            <div className={classnames(inputFieldRow, oneInputHalfRow)}>
              <PatientProfileInput
                name='patient_code'
                control={control}
                errors={errors}
                label={t('patient_code')}
                placeholder='011010000110110000001010'
              />
            </div>
          </FormBlock>
          <FormBlock title={t('personal_information')}>
            <div className={classnames(inputFieldRow, twoInputs)}>
              <PatientProfileInput
                name='first_name'
                control={control}
                errors={errors}
                label={t('first_name')}
                placeholder='Henrietta'
              />
              <PatientProfileInput
                name='last_name'
                control={control}
                errors={errors}
                label={t('family_name')}
                placeholder='Lacks'
              />
            </div>
            <div className={classnames(inputFieldRow, oneInputHalfRow)}>
              <PatientProfileInput
                name='birth_date'
                control={control}
                errors={errors}
                label={t('birth_date')}
                inputType='date'
              />
            </div>
            <div className={inputFieldRow}>
              <PatientProfileRadioGroup
                name='sex'
                control={control}
                errors={errors}
                options={sexOptions}
                label={t('sex')}
              />
            </div>
            <div className={classnames(inputFieldRow, oneInputFullRow)}>
              <PatientProfileInput
                name='address.street'
                control={control}
                errors={errors}
                placeholder='1 Royal Fort House'
                label={t('street_address')}
              />
            </div>
            <div className={classnames(inputFieldRow, twoInputs)}>
              <PatientProfileInput
                name='address.city'
                control={control}
                errors={errors}
                placeholder='Bristol'
                label={t('city')}
              />
              <PatientProfileInput
                name='address.state'
                control={control}
                errors={errors}
                placeholder='South West'
                label={t('province')}
              />
            </div>
            <div className={classnames(inputFieldRow, twoInputs)}>
              <PatientProfileInput
                name='address.zip'
                control={control}
                errors={errors}
                label={t('postal_code')}
                placeholder='BS8 1TH'
              />
              <PatientProfileInput
                name='address.country'
                control={control}
                errors={errors}
                label={t('country')}
                placeholder='United Kingdom'
              />
            </div>
            <div className={classnames(inputFieldRow, oneInputFullRow)}>
              <PatientProfileInput
                name='national_registry_number'
                control={control}
                errors={errors}
                label={t('national_registry_number')}
                placeholder='HeLa1920'
              />
            </div>
            <div className={classnames(inputFieldRow, oneInputHalfRow)}>
              <PatientProfileSelect
                name='preferred_language'
                control={control}
                errors={errors}
                options={languageOptions}
                label={t('language')}
              />
            </div>
          </FormBlock>
          <FormBlock title={t('contact_information')}>
            <div className={classnames(inputFieldRow, twoInputs)}>
              <PatientProfileInput
                name='email'
                control={control}
                errors={errors}
                label={t('email')}
                inputType='email'
                placeholder='h.lacks@hela.org'
                errorText={t('form_email_error_text')}
              />
              <PatientProfileInput
                name='mobile_phone'
                control={control}
                errors={errors}
                label={t('mobile_phone')}
                placeholder='+324562919700'
                inputType='tel'
              />
            </div>
            <div className={classnames(inputFieldRow, oneInputHalfRow)}>
              <PatientProfileInput
                name='phone'
                control={control}
                errors={errors}
                label={t('phone')}
                placeholder='+3225249797'
                inputType='tel'
              />
            </div>
          </FormBlock>

          <div className={classes.stepButtons}>
            <div>
              <Button
                color='primary'
                className={classes.cancelButton}
                onClick={goBack}
              >
                <>{t('go_back')}</>
              </Button>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <Button onClick={onSubmit}>
                <>{t('create_patient')}</>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

ImportAndCreatePatientForm.displayName = 'ImportAndCreatePatientForm'
