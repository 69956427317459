
import {
    format,
    isAfter,
    isPast,
    isSameDay,
    parseISO,
    startOfDay,
    subDays,
  } from 'date-fns'


export const getDate = (date: string): string => {
    
    const currentDate = new Date()
    const dateValue = parseISO(date)

    const startOfYesterday = startOfDay(subDays(currentDate, 1))

    if (isSameDay(dateValue, currentDate)) {
      return 'Today'
    }

    if (isPast(dateValue) && isAfter(dateValue, startOfYesterday)) {
      return 'Yesterday'
    }
    return format(dateValue, `iiii',' d MMMM yyyy`)
  }