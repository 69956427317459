import React, { type FC } from 'react'

interface Props {
  date: string
}

/**
 * Renders a date
 * All dates should be provided as ISO strings (see date-fns/formatISO)
 */
export const DateGroup: FC<Props> = ({
  date,
}) => {
  return (
    <div className='flex items-center relative'>
      <div className='flex items-center relative rounded-full bg-white border border-white px-6 py-2'>
        <div className='text-m text-slate-700 font-medium'>{date}</div>
      </div>
    </div>
  )
}

DateGroup.displayName = 'DateGroup'