import type { Colors } from '@awell/ui-kit/enums'
import type { LozengeColors } from '@awell/ui-kit/components/Lozenge'

import type { Activity as ActivityType } from '../../generated/types-orchestration'
import {
  type ActivityStatus,
  type ActivityAction,
  ActivityObjectType,
} from '../../hooks/useActivityFeed'

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export {
  ActivityStatus,
  ActivityObjectType,
  ActivityAction,
} from '../../hooks/useActivityFeed'

export interface DateRange {
  from: string
  to: string
}
export interface ActivitiesListFilters {
  activity_type: Array<ActivityObjectType>
  activity_status: Array<ActivityStatus>
  stakeholders: Array<string>
  action: Array<ActivityAction>
  hide_system_activities: boolean
  date_range?: DateRange
}
export interface ScheduledElement {
  id: string
  name: string
  start_date: string
  type?: string
}

export type Activity = ActivityType

export interface ActivityContent {
  id?: string
  summaryText: string | JSX.Element
  stepName: string
  stepLabelName: string
  stepLabelColor: Colors
  activityStatus: string
  activityStatusSummary: string
  activityStatusColor: LozengeColors
  timestampLabel: string
  timestamp: string
  ActivityIcon: JSX.Element | null
  buttonTextComplete: string
  buttonTextPending: string
  stakeholder?: string
  location?: string
  trackName: string
  lastUpdatedTimestamp: string | undefined
}

export interface ActivityEventType {
  id?: string
  content: EventContent
}

export interface EventContent {
  summaryText: string | JSX.Element
  timestamp: string
  // for now, avatar is either Ava or role initials (e.g. N for nurse)
  avatar: 'ava' | string
}

export const systemActivities = [
  ActivityObjectType.Track,
  ActivityObjectType.Step,
  ActivityObjectType.Action,
  ActivityObjectType.EvaluatedRule,
  ActivityObjectType.Reminder,
  ActivityObjectType.Patient,
  ActivityObjectType.Stakeholder,
  ActivityObjectType.User,
]

export enum ActivitySubjectType {
  ApiCall = 'API_CALL',
  Awell = 'AWELL',
  Plugin = 'PLUGIN',
  Stakeholder = 'STAKEHOLDER',
  User = 'USER',
}

export const DefaultActivityTypeOptions = [
  { value: 'MESSAGE', label: 'Message' },
  { value: 'API_CALL', label: 'Api Call' },
  { value: 'CALCULATION', label: 'Calculation' },
  { value: 'PLUGIN_ACTION', label: 'Extension Action' },
  { value: 'CHECKLIST', label: 'Checklist' },
  { value: 'CLINICAL_NOTE', label: 'Clinical Note' },
  { value: 'EMR_REPORT', label: 'Emr Report' },
  { value: 'EMR_REQUEST', label: 'Emr Request' },
  { value: 'FORM', label: 'Form' },
  { value: 'DECISION', label: 'Decision' },
  { value: 'TIMER', label: 'Timer' },
  { value: 'PATHWAY', label: 'Care flow' },
  { value: 'ACTION', label: 'Action' },
]

export const nonUserActivity = [
  ActivityObjectType.Calculation,
  ActivityObjectType.EmrReport,
  ActivityObjectType.ApiCall,
  ActivityObjectType.ClinicalNote,
  ActivityObjectType.PluginAction,
  ActivityObjectType.Decision,
  ActivityObjectType.Timer,
]
