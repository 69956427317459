import { type Column } from '@awell/ui-kit/components/Table'
import React from 'react'
import { capitalize } from 'lodash'
import { t } from 'i18next'
import { Text } from '@awell/ui-kit/components/Typography'

interface ContextColumns {
  key: string
  value: string
}
export const useClinicalNoteContextColumns = (): {
  columns: Array<Column<ContextColumns>>
} => {
  

  const columns: Array<Column<ContextColumns>> = [
    {
      title: capitalize(t('key')),
      field: 'key',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      // eslint-disable-next-line react/display-name
      render: ({ key }) => <Text>{key}</Text>,
      width: 90,
    },
    {
      title: capitalize(t('value')),
      field: 'value',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      // eslint-disable-next-line react/display-name
      render: ({ value }) => <Text>{value}</Text>,

      width: 90,
    },
  ]

  return { columns }
}
