import { Table } from '@awell/ui-kit/components/Table'
import { type RouteComponentProps } from '@reach/router'
import { isNil } from 'lodash'
import React, { useEffect, useState, type FC } from 'react'
import { t } from 'i18next'
import { DeletePathwayModal } from '../../../components/DeletePathwayModal'
import { FormBlock } from '../../../components/FormBlock'
import { StopPathwayModal } from '../../../components/StopPathwayModal'
import { usePatientPathways } from '../../../hooks/usePatientPathways'
import { PathwayStatus } from '../../Pathway/types'
import { type PatientPathway } from '../types'
import { PatientPathwaysTabSkeleton } from './skeletons'
import { usePatientPathwayListColumns } from './usePatientPathwayListColumns'

interface PatientPathwaysTabProps {
  patientId: string
  patientName: string
}

export const PatientPathwaysTab: FC<
  RouteComponentProps<PatientPathwaysTabProps>
> = ({ patientId, patientName }) => {
  

  const [selectedPathwayForDeletion, setSelectedPathwayForDeletion] =
    useState<PatientPathway>()
  const [openModalToDeletePathway, setOpenModalToDeletePathway] =
    useState<boolean>(false)

  const [selectedPathwayToStop, setSelectedPathwayToStop] =
    useState<PatientPathway>()
  const [openModalToStopPathway, setOpenModalToStopPathway] =
    useState<boolean>(false)

  const { refetch, patientPathways, loading } = usePatientPathways(patientId)

  useEffect(() => {
    if (!loading) {
      refetch()
    }
  }, [])

  const activePatientPathways =
    patientPathways?.filter(
      pathway =>
        pathway.status === PathwayStatus.Active ||
        pathway.status === PathwayStatus.Starting,
    ) ?? []

  const completedPatientPathways =
    patientPathways?.filter(
      pathway => pathway.status === PathwayStatus.Completed,
    ) ?? []

  const stoppedPatientPathways =
    patientPathways?.filter(
      pathway => pathway.status === PathwayStatus.Stopped,
    ) ?? []

  const openDeleteConfirmationModal = (pathway: PatientPathway): void => {
    setSelectedPathwayForDeletion(pathway)
    setOpenModalToDeletePathway(true)
  }

  const openStopConfirmationModal = (pathway: PatientPathway): void => {
    setSelectedPathwayToStop(pathway)
    setOpenModalToStopPathway(true)
  }

  const handleStoppedModalClose = (): void => {
    setSelectedPathwayToStop(undefined)
    setOpenModalToStopPathway(false)
  }

  const handleDeleteModalClose = (): void => {
    setSelectedPathwayForDeletion(undefined)
    setOpenModalToDeletePathway(false)
  }

  const {
    columnsForActivePathways,
    columnsForCompletedPathways,
    columnsForStoppedPathways,
  } = usePatientPathwayListColumns({
    onDeletePathway: openDeleteConfirmationModal,
    onStopPathway: openStopConfirmationModal,
  })

  if (loading && isNil(activePatientPathways)) {
    return <PatientPathwaysTabSkeleton />
  }

  return (
    <>
      <FormBlock title={t('active_pathways')}>
        <Table
          rows={activePatientPathways}
          actionText=''
          emptyDataSourceMessage={t('no_pathway_found')}
          columns={columnsForActivePathways}
          paging
          fullWidth
        />
      </FormBlock>

      <FormBlock title={t('completed_pathways')}>
        <Table
          rows={completedPatientPathways}
          actionText=''
          emptyDataSourceMessage={t('no_pathway_found')}
          columns={columnsForCompletedPathways}
          paging
          fullWidth
        />
      </FormBlock>

      <FormBlock title={t('stopped_pathways')}>
        <Table
          rows={stoppedPatientPathways}
          actionText=''
          emptyDataSourceMessage={t('no_pathway_found')}
          columns={columnsForStoppedPathways}
          paging
          fullWidth
        />
      </FormBlock>

      <DeletePathwayModal
        open={openModalToDeletePathway}
        onClose={handleDeleteModalClose}
        title={t('delete_patient_pathway_heading', {
          patientName,
          pathwayName: selectedPathwayToStop?.title ?? '',
        })}
        pathway_id={selectedPathwayForDeletion?.id}
        patient_id={patientId}
      />

      <StopPathwayModal
        open={openModalToStopPathway}
        onClose={handleStoppedModalClose}
        title={t('stop_patient_pathway_heading', {
          patientName,
          pathwayName: selectedPathwayForDeletion?.title ?? '',
        })}
        pathway_id={selectedPathwayToStop?.id}
      />
    </>
  )
}

PatientPathwaysTab.displayName = 'PatientPathwaysTab'
