import React, { type FC } from 'react'

import {
  type BaselineDataPoint,
} from '@awell/ui-kit/components/AwellActivity'

import { type Activity, nonUserActivity } from '../../types'
import { AwellActivityCard } from './AwellActivityCard'
import { ActivityFeedCard } from './ActivityFeedCard'

interface Props {
  activity: Activity
  selected: boolean
  onSelect?: () => void
  baselineDataPoints?: Array<BaselineDataPoint>
  pathwayStatusExplanation?: string
}

export const FeedItem: FC<Props> = ({
  activity,
  selected,
  onSelect = () => null,
  baselineDataPoints,
  pathwayStatusExplanation,
}) => {
  const useActivityFeedCard =
    activity.isUserActivity || nonUserActivity.includes(activity.object.type)

  if (useActivityFeedCard) {
    return (
      <ActivityFeedCard
        activity={activity}
        onSelect={onSelect}
        selected={selected}
      />
    )
  }

  return (
    <AwellActivityCard
      activity={activity}
      baselineDataPoints={baselineDataPoints}
      pathwayStatusExplanation={pathwayStatusExplanation}
    />
  )
}

FeedItem.displayName = 'FeedItem'
