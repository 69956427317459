/* eslint-disable @typescript-eslint/no-misused-promises */
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import React, { type FC, type ReactElement } from 'react'
import { t } from 'i18next'
import { useStyles } from '../useStyles'

import { Button } from '@awell/ui-kit/components/Button'
import { useNavigate } from '@reach/router'
import { type Patient } from '../../../types'
import { CheckmarkRound } from '@awell/ui-kit/components/Icons'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'
import { colors } from '@awell/ui-kit/utils/style-guide'

interface ShowCreatedPatientAndCTAProps {
  createdPatient: Patient
  onClickGoToFirstStep: () => void
}

export const ShowCreatedPatientAndCTA: FC<ShowCreatedPatientAndCTAProps> = ({
  createdPatient,
  onClickGoToFirstStep,
}): ReactElement => {
  const classes = useStyles()
  
  const navigate = useNavigate()

  return (
    <div className={classes.createdPatientAndCtaContainer}>
      <IconWrapper
        className={classes.importPatientSuccessIcon}
        color={colors.green500}
        background={true}
        size='xl'
      >
        <CheckmarkRound />
      </IconWrapper>

      <Type variant={TypeVariants.h4} className={classes.importPatientTitle}>
        {t('import_created_profile_successfully', {
          name: `${createdPatient.profile?.first_name ?? ''} ${
            createdPatient.profile?.last_name ?? ''
          }`,
        })}
      </Type>
      <Button
        color='primary'
        className={classes.cancelButton}
        onClick={onClickGoToFirstStep}
      >
        <>{t('import_another_patient')}</>
      </Button>
      <Button
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={async () => {
          await navigate(`/patients/${createdPatient.id}`)
        }}
      >
        <>{t('go_to_patient_profile')}</>
      </Button>
    </div>
  )
}

ShowCreatedPatientAndCTA.displayName = 'ShowCreatedPatientAndCTA'
