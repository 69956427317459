import { Spinner } from '@awell/ui-kit/components/Spinner'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { Accordion } from '@awell/ui-kit/components/Accordion'
import { Button } from '@awell/ui-kit/components/Button'
import { type Activity } from '../types'
import { useGeneratedClinicalNote } from '../../../hooks/useClinicalNote'

interface ClinicalNoteActivityDetailsProps {
  activity: Activity
}

const useStyles = makeStyles({
  clinicalNarrative: {
    marginBottom: spacing.xs,
  },
  expandButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: spacing.xs,
  },
})

export const ClinicalNoteActivityDetails = ({
  activity,
}: ClinicalNoteActivityDetailsProps): JSX.Element => {
  const classes = useStyles()
  

  const { loading, clinicalNote } = useGeneratedClinicalNote(activity.object.id)

  const [expanded, setExpanded] = useState<Array<string>>([])

  const getAllNarrativesIds = (): Array<string> => {
    return clinicalNote!.narratives.map(({ id }) => id)
  }
  const handleChange = (panelId: string) => {
    const isExpanded = expanded.includes(panelId)

    const newData = !isExpanded
      ? [...expanded, panelId]
      : expanded.filter(id => id !== panelId)
    setExpanded(newData)
  }

  const handleManageAll = () => {
    if (expanded.length > 0) {
      setExpanded([])
      return
    }
    const allIds = getAllNarrativesIds()
    setExpanded(allIds)
  }

  useEffect(() => {
    if (!loading) {
      const allIds = getAllNarrativesIds()
      setExpanded(allIds)
    }
  }, [loading])

  if (loading) {
    return <Spinner />
  }
  return (
    <div>
      <div className={classes.expandButtonWrapper}>
        <Button variant='text' onClick={handleManageAll} size='small'>
          {expanded.length > 0 ? t('collapse_all') : t('expand_all')}
        </Button>
      </div>

      {clinicalNote!.narratives.map(({ title, body, id }) => {
        const isExpanded = expanded.includes(id)
        return (
          <div key={`${activity.id}-${id}`} className={classes.clinicalNarrative}>
            <Accordion
              title={title}
              body={body}
              id={id}
              expanded={isExpanded}
              onExpandToggle={handleChange}
            />
          </div>
        )
      })}
    </div>
  )
}

ClinicalNoteActivityDetails.displayName = 'ClinicalNoteActivityDetails'
