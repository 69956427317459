
import { isNil } from 'lodash'
import { ActivityObjectType, type Activity } from '../../types'

export const createActivityTitle = (activity: Activity): string => {
    if (
      !isNil(activity) &&
      activity.object.type === ActivityObjectType.Decision
    ) {
      const outcomeSubactivity = activity.sub_activities.find(
        a => !isNil(a?.text?.en) && a.text.en.includes('Evaluated outcome'),
      )
      const outcomeText = outcomeSubactivity?.text?.en ?? ''
      return `Decision - ${outcomeText}`
    }
    return activity?.action_component?.title ?? activity?.object?.name ?? ''
  }