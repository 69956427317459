import React, { type FC } from 'react'
import { t } from 'i18next'
import { format } from 'date-fns'
import {
  TableBody,
  Table,
  TableHeader,
  TableHead,
  TableRow,
  TableCell,
  Icon,
} from '@awell-health/design-system'

import type { ScheduledElement } from '../../types'

interface Props {
  scheduledElements: Array<ScheduledElement>
}

const TABLE_COLUMNS = [
  {
    field: 'element',
    title: 'Element',
    sorting: true,
  },
  {
    field: 'type',
    title: 'Type',
    sorting: false,
  },
  {
    field: 'start_date',
    title: 'Scheduled for',
    sorting: true,
  },
]

export const ScheduledElementsCard: FC<Props> = ({ scheduledElements }) => {
  
  return (
    <div className='shadow rounded-md p-6 bg-white border border-gray-200 w-[650px]'>
      <div className='flex items-center gap-3 mb-4'>
        <div className='rounded-md bg-gray-200 p-2 w-fit'>
          <Icon icon='RiTimeLine' size={20} />
        </div>
        <div className='flex justify-between items-center w-full'>
          {t('activityfeed:scheduled_elements_summary')}
        </div>
      </div>
      <div>
        <div className='max-h-[150px] overflow-y-auto'>
          <Table>
            <TableHeader>
              <TableRow>
                {TABLE_COLUMNS.map(({ field, title, sorting }) => (
                  <TableHead key={field}>{title}</TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {scheduledElements.map((element, index) => (
                <TableRow key={`${index}-${element.id}`}>
                  <TableCell key='element'>{element.name}</TableCell>
                  <TableCell key='type'>
                    {element.type === 'track' ? 'Track' : 'Step'}
                  </TableCell>
                  <TableCell key='start_date'>
                    {format(
                      new Date(element.start_date),
                      'd-LLL-yyyy K:mm:ss a',
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

ScheduledElementsCard.displayName = 'ScheduledElementsCard'
