import { t } from 'i18next'
import { useNotifications } from '../useNotifications'
import { useStopTrackMutation, type StopTrackInput } from './types'

interface UseStopTrackHook {
  stopTrack: (input: StopTrackInput) => Promise<boolean>
}

export const useStopTrack = (): UseStopTrackHook => {
  const { notifyError } = useNotifications()
  

  const [stopTrackMutation] = useStopTrackMutation()

  const stopTrack = async (input: StopTrackInput) => {
    try {
      await stopTrackMutation({
        variables: {
          input,
        },
        refetchQueries: ['PathwayActivities', 'GetPathway', 'PathwayElements'],
        awaitRefetchQueries: true,
        fetchPolicy: 'no-cache',
      })
      return true
    } catch (error) {
      notifyError({
        message: t('stop_track_error'),
        error,
      })
    }
    return false
  }

  return {
    stopTrack,
  }
}
