import { Button } from '@awell/ui-kit/components/Button'
import { useFormStyles } from '@awell/ui-kit/components/Form'
import classnames from 'classnames'
import React, { type FC } from 'react'
import { useForm } from 'react-hook-form'
import { t } from 'i18next'
import { FormBlock } from '../../../../components/FormBlock'
import { useUpdatePatient } from '../../../../hooks/useUpdatePatient'
import { PatientProfileInput } from '../../FormInputs'
import { type Patient, type PatientProfile } from '../../types'
import { useStyles } from '../../useStyles'

type ContactInformation = Pick<
  PatientProfile,
  'mobile_phone' | 'email' | 'phone'
>

interface PatientContactInformationProps {
  patient: Patient
}

export const PatientContactInformation: FC<PatientContactInformationProps> = ({
  patient,
}) => {
  
  const { saveBtnContainer } = useStyles()
  const { inputFieldRow, twoInputs, oneInputHalfRow } = useFormStyles()
  const { control, errors, handleSubmit } = useForm<ContactInformation>({
    defaultValues: patient?.profile ?? undefined,
  })
  const { updatePatient } = useUpdatePatient(patient.id)

  const onSubmit = (): void => {
    void handleSubmit(async data => {
      await updatePatient(data)
    })()
  }

  return (
    <FormBlock title={t('contact_information')}>
      <form onSubmit={() => handleSubmit(onSubmit)}>
        <div className={classnames(inputFieldRow, twoInputs)}>
          <PatientProfileInput
            name='email'
            control={control}
            errors={errors}
            label={t('email')}
            inputType='email'
            placeholder='h.lacks@hela.org'
            errorText={t('form_email_error_text')}
          />
          <PatientProfileInput
            name='mobile_phone'
            control={control}
            errors={errors}
            label={t('mobile_phone')}
            placeholder='+324562919700'
            inputType='tel'
          />
        </div>
        <div className={classnames(inputFieldRow, oneInputHalfRow)}>
          <PatientProfileInput
            name='phone'
            control={control}
            errors={errors}
            label={t('phone')}
            placeholder='+3225249797'
            inputType='tel'
          />
        </div>
        <div className={saveBtnContainer}>
          <Button onClick={onSubmit}>{t('save')}</Button>
        </div>
      </form>
    </FormBlock>
  )
}
