import React, { type FC } from 'react'
import { useStyles } from './useStyles'
import { Heading5, Text } from '../Typography'
import { colors } from '../../utils/style-guide'

export interface ActivityCardSummaryProps {
  ActivityIcon: JSX.Element | null
  stepName: string
  trackName: string
}

export const ActivityCardSummary: FC<ActivityCardSummaryProps> = ({
  ActivityIcon,
  children,
  stepName,
  trackName,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.summaryDetails}>
      <div className={classes.activityContent}>
        <div className={classes.activityContentDetails}>
          <div className={classes.activityIcon}>{ActivityIcon}</div>
          <div>
            <Heading5 variant='subHeadline'>{children}</Heading5>
            <div className={classes.cardDescription}>
              <Text variant='textSmall' color={colors.neutralMid300}>
                {trackName && `${trackName} / `}
                {stepName}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ActivityCardSummary.displayName = 'ActivityCardSummary'
