import { t } from 'i18next'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { useNotifications } from '../useNotifications'

interface useCopyToClipboardHook {
  copyToClipboard: (text: string) => void
}

export const useCopyToClipboard = (): useCopyToClipboardHook => {
  const { notify, notifyError } = useNotifications()
  

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      notify({
        message: t('copy_to_clipboard_success'),
        notifyType: NotificationTypes.success,
      })
    } catch (e) {
      notifyError({
        message: t('copy_to_clipboard_error'),
      })
    }
  }
  return { copyToClipboard }
}
