import { Spinner } from '@awell/ui-kit/components/Spinner'
import React, { useState } from 'react'
import { t } from 'i18next'
import {
  SubHeading,
  Type,
  TypeVariants,
} from '@awell/ui-kit/components/Typography'
import {
  ActivityList,
  SidePanelCardVariants,
} from '@awell/ui-kit/components/ActivityList'
import { ActivityPanelHeader } from '@awell/ui-kit/compositions/ActivityFeed'
import { isNil } from 'lodash'
import { StepPanelHeader } from '@awell/ui-kit/components/StepFeedPanel'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import {
  AlertModal,
  type AlertModalLabels,
} from '@awell/ui-kit/components/AlertModal'
import { Button } from '@awell/ui-kit/components/Button'
import { ActivityDetails } from '../../components/ActivityDetails'
import { PathwaySidePanelLayout } from '../../components/PathwaySidePanelLayout'
import { usePathwayContextProvider } from '../../components/PathwayContextProvider'
import {
  ActivityObjectType,
  ActivityStatus,
  ElementStatus,
  ElementType,
} from './types'
import { RetryPushToEmrButton } from '../../components/RetryEmrReportButton'
import { RetryActivityButton } from '../../components/RetryActivityButton'
import { useStopTrack } from '../../hooks/useStopTrack'

export const PathwaySidePanel = (): JSX.Element => {
  
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { stopTrack } = useStopTrack()
  const {
    onSelectActivity,
    onSelectElement,
    selectedActivity,
    onClosePanel,
    pendingActivities,
    loading,
    selectedElement,
    filteredActivities,
  } = usePathwayContextProvider()

  if (loading) {
    return <Spinner />
  }
  const stopTrackModalLabels: AlertModalLabels = {
    heading: t('stop_track_heading', { trackName: selectedElement?.name }),
    primaryAction: t('stop'),
    secondaryAction: t('cancel'),
  }

  const openStopTrackConfirmationModal = (): void => {
    setModalOpen(true)
  }
  const handleModalClose = (): void => {
    setModalOpen(false)
  }

  const handleStopTrack = async (): Promise<void> => {
    if (!isNil(selectedElement)) {
      await stopTrack({
        pathway_id: selectedElement.context.pathway_id,
        track_id: selectedElement.id,
      })
    }
    onSelectActivity(undefined)
    onSelectElement(undefined)
    handleModalClose()
    onClosePanel()
  }

  const showingActivity = !isNil(selectedActivity)
  const showingFilteredActivities =
    !isNil(selectedElement) && isNil(selectedActivity)

  const getAdditionalActionButtons = (): JSX.Element | undefined => {
    if (showingActivity) {
      const showPushToEmrRetryActionButton =
        selectedActivity.object.type === ActivityObjectType.EmrReport

      const showRetryActivityButton = [
        ActivityObjectType.PluginAction,
        ActivityObjectType.ApiCall,
      ].includes(selectedActivity.object.type)

      if (showPushToEmrRetryActionButton) {
        return <RetryPushToEmrButton id={selectedActivity.id} />
      }
      if (showRetryActivityButton) {
        return (
          <RetryActivityButton
            id={selectedActivity.id}
            disabled={selectedActivity.status === ActivityStatus.Active}
          />
        )
      }
    } else if (
      selectedElement?.type === ElementType.Track &&
      selectedElement.status === ElementStatus.Active
    ) {
      return (
        <Button
          onClick={() => {
            openStopTrackConfirmationModal()
          }}
        >
          {t('stop')}
        </Button>
      )
    }
  }

  const getCopyUrl = (): string => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    if (isNil(selectedActivity)) {
      params.delete('activityId')
      url.search = params.toString()
      return url.toString()
    }
    params.set('activityId', selectedActivity.id)
    url.search = params.toString()
    return url.toString()
  }

  return (
    <>
      <PathwaySidePanelLayout
        showTabs={showingActivity}
        actionButtons={getAdditionalActionButtons()}
        showCopyButton={showingActivity}
        copyUrl={getCopyUrl()}
      >
        <PathwaySidePanelLayout.Header>
          {showingFilteredActivities &&
            selectedElement.type === ElementType.Step && (
              <StepPanelHeader element={selectedElement} />
            )}
          {showingFilteredActivities &&
            selectedElement.type === ElementType.Track && (
              <div style={{ marginTop: spacing.xxs }}>
                <SubHeading>{selectedElement.name}</SubHeading>
              </div>
            )}
          {showingActivity && (
            <ActivityPanelHeader activity={selectedActivity} />
          )}
          {!showingFilteredActivities && !showingActivity && (
            <div style={{ marginTop: spacing.xxs }}>
              <SubHeading>{t('pending_activities_title')}</SubHeading>
            </div>
          )}
        </PathwaySidePanelLayout.Header>
        <PathwaySidePanelLayout.Content>
          {showingActivity && (
            <ActivityDetails
              activity={selectedActivity}
              onClose={onClosePanel}
            />
          )}
          {!showingActivity && (
            <ActivityList
              // @ts-expect-error fix types
              activities={
                showingFilteredActivities
                  ? filteredActivities
                  : pendingActivities
              }
              // @ts-expect-error fix types
              onSelectActivity={onSelectActivity}
              variant={
                showingFilteredActivities
                  ? SidePanelCardVariants.Step
                  : SidePanelCardVariants.Pending
              }
            />
          )}
        </PathwaySidePanelLayout.Content>
      </PathwaySidePanelLayout>
      <AlertModal
        labels={stopTrackModalLabels}
        open={modalOpen}
        onAction={handleStopTrack}
        onClose={handleModalClose}
        appearance='danger'
      >
        <Type variant={TypeVariants.regular}>
          {t('stop_track_explanation', {
            trackName: selectedElement?.name ?? '',
          })}
        </Type>
      </AlertModal>
    </>
  )
}
