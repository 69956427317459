import { InlineText, Text } from '../../Typography'
import { capitalize, isEmpty } from 'lodash'
import { colors, spacing } from '../../../utils/style-guide'
import React from 'react'
import { type ActivityObject } from '../../../generated/types-design'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useStyles } from './useStyles'

interface ExtensionActivityStakeholdersProps {
  stakeholders: Array<ActivityObject>
}
export const ExtensionActivityStakeholders = ({
  stakeholders,
}: ExtensionActivityStakeholdersProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  if (isEmpty(stakeholders)) {
    return <></>
  }

  return (
    <div className={classes.field}>
      <Text variant='textMedium'>{capitalize(t('stakeholder_other'))}:</Text>
      <div>
        {stakeholders.map(({ type, email, id }, index) => {
          return (
            <div
              key={id}
              className={classnames({
                [classes.stakeholderWrapper]:
                  index !== 1 && index !== stakeholders.length,
              })}
            >
              <InlineText spacing={`0 ${spacing.xxxs} 0 0`}>
                {email ?? 'email unavailable'}
              </InlineText>
              <InlineText variant='textSmallBold' color={colors.neutralMid200}>
                ({type})
              </InlineText>
            </div>
          )
        })}
      </div>
    </div>
  )
}
