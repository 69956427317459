import classnames from 'classnames'
import { isNil } from 'lodash'
import React, { type FC } from 'react'
import { findChildByType } from '../../utils/findChildByType'
import { Timestamp } from '../ActivityFeedCard/Timestamp'
import { IconWrapper } from '../IconWrapper'
import { Text } from '../Typography'
import { useStyles } from './useStyles'

const Icon: FC = ({ children }) => (
  <IconWrapper size='m'>{children}</IconWrapper>
)

const Summary: FC = ({ children }) => <>{children}</>
const Extra: FC = ({ children }) => <>{children}</>

interface Props {
  useCard?: boolean
  timestamp?: string
}

export const AwellActivityLayout = ({
  useCard = false,
  timestamp,
  children,
}: Props & { children: Array<React.ReactChild> }): React.ReactElement => {
  const classes = useStyles()
  const icon = findChildByType(children, Icon)
  const summary = findChildByType(children, Summary)
  const extra = findChildByType(children, Extra)

  return (
    <div
      className={classnames({
        [classes.container]: !useCard,
        [classes.card]: useCard,
      })}
    >
      <div className={classes.main}>
        <div className={classes.iconWrapper}>{icon}</div>
        <div className={classes.textContainer}>
          <Text>{summary}</Text>
        </div>
      </div>
      <div className={classes.extra}>
        {extra}
        {!isNil(timestamp) && (
          <Timestamp
            timestamp={timestamp}
          />
        )}
      </div>
    </div>
  )
}

AwellActivityLayout.displayName = 'AwellActivityLayout'
AwellActivityLayout.Icon = Icon
AwellActivityLayout.Summary = Summary
AwellActivityLayout.Extra = Extra
