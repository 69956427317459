import { FullHeightRouter } from '@awell/libs-web/routing'
import { Redirect, type RouteComponentProps } from '@reach/router'
import React, { type FC } from 'react'
import { CareAppPageLayout } from '../components/CareAppPageLayout'
import { currentPageTitleVar } from '../local-state'

// TODO when home route gets re-enabled we need to redirect to clinical app here like in PathwaysRoute
export const LogsRoute: FC<RouteComponentProps> = () => {
  currentPageTitleVar('Awell Care Logs')
  return (
    <FullHeightRouter primary={false}>
      <CareAppPageLayout path='/'>
        <Redirect from='/' to='webhooks' />
      </CareAppPageLayout>
    </FullHeightRouter>
  )
}

LogsRoute.displayName = 'LogsPage'
