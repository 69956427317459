import React, { type FC, useEffect, useState } from 'react'
import { t } from 'i18next'
import { Table } from '@awell/ui-kit/components/Table'
import {
  type FilterVariants,
  SearchFilterBar,
} from '@awell/ui-kit/components/SearchFilterBar'
import type { RouteComponentProps } from '@reach/router'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { isEmpty, uniqBy } from 'lodash'
import {
  DropdownButton,
  DropdownOption,
} from '@awell/ui-kit/components/DropdownButton'
import { useWebhookCallsListColumns } from './useWebhookCallsListColumns'

import { usePathwayContext } from '../../../hooks/usePathwayContext'
import { WebhookCallListSkeleton } from './skeleton'
import {
  useGetPathwayWebhookCalls,
  type WebhookCall,
  type WebhookCallResponse,
} from '../../../hooks/useGetPathwayWebhookCalls'
import { useRetryAllFailedWebhookCalls } from '../../../hooks/useRetryAllFailedWebhookCalls'
import { useRetryAllWebhookCalls } from '../../../hooks/useRetryAllWebhookCalls'
import { useStyles } from './useStyles'

interface WebhookFilters {
  statusCodes: Array<string>
  query: string
}

export const WebhooksCallList: FC<RouteComponentProps> = () => {
  
  const { columns } = useWebhookCallsListColumns()
  const { pathwayId } = usePathwayContext()
  const classes = useStyles()

  const { loading, webhookCalls } = useGetPathwayWebhookCalls(pathwayId!)
  const { retryFailedWebhookCalls } = useRetryAllFailedWebhookCalls(pathwayId!)
  const { retryAllWebhookCalls } = useRetryAllWebhookCalls(pathwayId!)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [appliedFilters, setFilters] = useState<WebhookFilters>({
    query: '',
    statusCodes: [],
  })
  const [rows, setRows] = useState<Array<WebhookCall>>([])

  useEffect(() => {
    if (!loading) {
      setRows(webhookCalls ?? [])
    }
  }, [loading, webhookCalls])

  if (loading) return <WebhookCallListSkeleton />
  const getStatusCodeOptions = (_data: Array<WebhookCall>) => {
    return uniqBy(
      _data
        .map(d => d.responses)
        .flat()
        .map(({ status }) => ({
          label: `${status}`,
          value: `${status}`,
        })),
      'value',
    )
  }

  const filters: Array<FilterVariants> = [
    {
      label: t('status'),
      name: 'statusCodes',
      type: 'select',
      options: getStatusCodeOptions(webhookCalls ?? []),
    },
  ]

  const handleSearchByName = (webhook_name: string, query: string): boolean => {
    if (query === '') {
      return true
    }

    return webhook_name.toLowerCase().includes(query.toLowerCase())
  }

  const handleStatusCodeFilter = (
    responses: Array<WebhookCallResponse>,
    codes: Array<string>,
  ): boolean => {
    if (isEmpty(codes)) {
      return true
    }
    if (isEmpty(responses)) {
      return false
    }
    return codes.includes(`${responses[responses.length - 1].status}`)
  }

  const handleSearchAndFilters = ({ statusCodes, query }: WebhookFilters) => {
    setFilters({
      statusCodes,
      query,
    })
    const newRows = webhookCalls?.filter(({ responses, webhook_name }) => {
      return (
        handleStatusCodeFilter(responses, statusCodes) &&
        handleSearchByName(webhook_name, query)
      )
    })

    setRows(newRows ?? [])
  }
  const handleRetryAll = () => {
    retryAllWebhookCalls()
    setDropdownOpen(false)
  }

  const handleRetryFailed = () => {
    retryFailedWebhookCalls()
    setDropdownOpen(false)
  }

  return (
    <div>
      <div className={classes.filtersContainer}>
        <SearchFilterBar<WebhookFilters>
          onSearchFilter={handleSearchAndFilters}
          filters={filters}
          appliedFilters={appliedFilters}
          placeholder={t('webhook_search_placeholder')}
          customSpacing={`${spacing.xs} 0`}
          hideBorder
        />
        <DropdownButton
          label={t('manage')}
          isOpen={dropdownOpen}
          onClick={setDropdownOpen}
        >
          <DropdownOption
            title={t('webhook_retry_all')}
            onClick={handleRetryAll}
            data-heap='webhook-calls-retry-all-btn'
            data-product-tour-target='webhook-calls-retry-all-btn'
          />

          <DropdownOption
            title={t('webhook_retry_all_failed')}
            onClick={handleRetryFailed}
            data-heap='webhook-calls-retry-failed-btn'
            data-product-tour-target='webhook-calls-retry-failed-btn'
          />
        </DropdownButton>
      </div>
      <div>
        <Table
          columns={columns}
          rows={rows}
          fullWidth
          paging
          nonTablePageContentHeight={500}
        />
      </div>
    </div>
  )
}

WebhooksCallList.displayName = 'WebhooksCallList'
