import { t } from 'i18next'
import { useGetExtensionActivityRecordQuery } from './types'
import type { ExtensionActivityRecord } from './types'
import { useNotifications } from '../useNotifications'
import { isNil } from 'lodash'
import { useEffect } from 'react'

interface UseExtensionActivityRecordHook {
  loading: boolean
  extensionActivityRecord?: ExtensionActivityRecord
}

export const useExtensionActivityRecord = (
  id: string,
): UseExtensionActivityRecordHook => {
  const { notifyError } = useNotifications()
  

  const { data, loading, error, refetch } = useGetExtensionActivityRecordQuery({
    variables: {
      id,
    },
    nextFetchPolicy: 'cache-first',
  })

  useEffect(() => {
    void refetch()
  }, [id])

  if (!isNil(error)) {
    notifyError({
      message: t('error_load_extension_record'),
      error,
    })
    return { loading: false }
  }
  if (loading) {
    return { loading: true }
  }

  return {
    loading: false,
    // TODO: CLEANUP
    extensionActivityRecord: data?.extensionActivityRecord.record,
  }
}
