import {
  AppHeader as BaseAppHeader,
  AppHeaderSkeleton,
  useHeaderStyles,
} from '@awell/ui-kit/components/AppHeader'
import { Button, IconButton } from '@awell/ui-kit/components/Button'
import { HelpOutline, User } from '@awell/ui-kit/components/Icons'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'
import { Menu, MenuItem } from '@awell/ui-kit/components/Menu'
import { LinkTab } from '@awell/ui-kit/components/Tabs'
import {
  type RouteComponentProps,
  useLocation,
  useNavigate,
} from '@reach/router'
import React, { type FC, useState } from 'react'
import { t } from 'i18next'
import classnames from 'classnames'
import { Tooltip } from '@awell/ui-kit/components/Tooltip'
import { AppSwitcher } from '@awell/ui-kit/components/AppSwitcher'
import { type SwitchableApp } from '@awell/ui-kit/components/AppSwitcher/types'
import { environment } from '@awell/libs-web/environment'
import { useWhoami } from '../../hooks/useWhoami'
import { useStyles } from './useStyles'
import { PathwayNavigationBar } from '../../pages/Pathway/PathwayNavigationBar'
import { isNil } from 'lodash'
import { useStytchMember, useStytchIsAuthorized } from '@stytch/react/b2b'
import { useAnalytics } from 'use-analytics'
import { useTrackCustify } from '@awell/libs-web/analytics'
import { CustifyEvents } from '@awell/libs-web/experiments'

export const AppHeader: FC<RouteComponentProps> = () => {
  const classes = useStyles()
  
  const { appHeader, appHeaderBoxShadow } = useHeaderStyles()
  const { member } = useStytchMember()

  const { isAuthorized: canAccessPatients } = useStytchIsAuthorized(
    'patients',
    'read',
  )
  const { isAuthorized: canAccessWorklist } = useStytchIsAuthorized(
    'worklist',
    'worklist_read',
  )
  const { user: currentUser, loading } = useWhoami()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { identify } = useAnalytics()
  const { trackCustify } = useTrackCustify()

  const isCurrentTabPath = (tabName: string): boolean =>
    pathname.indexOf(tabName) === 0

  const handleAppSwitch = (app: SwitchableApp): void => {
    switch (app) {
      case 'home':
        void trackCustify(CustifyEvents.GO_CARE_TO_CAREOPS)
        void navigate(environment.urls.careops)
        break
      case 'design':
        void trackCustify(CustifyEvents.GO_CARE_TO_STUDIO)
        void navigate(`${environment.urls.pathway_studio}`)
        break
      default:
    }
  }

  if (loading) {
    return <AppHeaderSkeleton />
  }

  const handleLogout = (): void => {
    void navigate('/logout')
  }
  const isNotInDefaultTenant = currentUser?.tenant?.is_default !== true
  const isPreview = pathname.match(/(\/pathway\/([^/]*))(\/.*)?/)
  const tenantName = currentUser?.tenant?.name ?? ''
  const tenantId = currentUser?.tenant_id ?? ''
  const user_id = member?.member_id ?? currentUser?.id

  identify(user_id ?? '', {
    email: member?.email_address ?? currentUser?.profile?.email,
    company: {
      id: member?.organization_id ?? tenantId,
    },
  }).catch(() => {
    /* Nothing to do here, if this calls fails there is no need to panic or block the app usage */
  })
  const outGoingWebhooksUrl = `${environment.urls.careops}/monitor/webhooks/outgoing`
  return (
    <BaseAppHeader
      className={classnames(appHeader, {
        [appHeaderBoxShadow]: !isPreview,
      })}
    >
      {isNil(isPreview) ? (
        <div className={classes.logo}>
          <AppSwitcher currentApp='operate' onSwitch={handleAppSwitch} />
        </div>
      ) : (
        <></>
      )}

      {!isNil(isPreview) ? (
        <PathwayNavigationBar
          id={pathname.split('/')[2]}
          isNotInDefaultTenant={isNotInDefaultTenant}
        />
      ) : (
        <></>
      )}

      {isNotInDefaultTenant && isNil(isPreview) ? (
        <div className={classes.tabButtons}>
          {canAccessPatients && (
            <>
              <LinkTab
                tabLabel={t('patient_other')}
                selected={isCurrentTabPath('/patients')}
                linkToLocation='patients'
              />
              <LinkTab
                tabLabel={t('activities')}
                selected={isCurrentTabPath('/activities')}
                linkToLocation='activities'
              />
            </>
          )}
          {canAccessWorklist && (
            <LinkTab
              tabLabel={t('worklists')}
              selected={isCurrentTabPath('/worklists')}
              linkToLocation='worklists'
            />
          )}
        </div>
      ) : (
        <></>
      )}
      <div className={classes.grow} />
      <div>
        <Tooltip info={`${t('help')}`} arrow placement='left'>
          <IconButton
            target='_blank'
            href={t('link_awell_help')}
            data-product-tour-target='awell-help-btn'
            rel='noopener'
            iconSize='s'
          >
            <HelpOutline />
          </IconButton>
        </Tooltip>
        <Menu
          open={isMenuOpen}
          onClose={() => {
            setIsMenuOpen(false)
          }}
          onOpen={() => {
            setIsMenuOpen(true)
          }}
          toggleButtonContent={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <IconWrapper size='s'>
              <User />
            </IconWrapper>
          }
          dropdownLeft
          dataCy='pathway-header-user-menu'
          data-product-tour-target='profile-btn'
          tooltipText={t('your_profile_and_settings')}
        >
          <MenuItem disabled>
            {t('logged_in_user_email', {
              email: currentUser?.profile?.email,
            })}
          </MenuItem>
          <MenuItem disabled>
            {/* allows the tenant id to be copied */}
            <span style={{ userSelect: 'text' }}>
              {`${tenantName} (${tenantId})`}
            </span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.open(outGoingWebhooksUrl, '_blank')
            }}
          >
            {t('webhook_logs')}
          </MenuItem>
          <MenuItem
            dataCy='pathway-user-logout-btn'
            onClick={() => {
              handleLogout()
            }}
          >
            {t('logout')}
          </MenuItem>
        </Menu>
        {isNotInDefaultTenant && canAccessPatients && (
          <Button
            style={{ marginLeft: '8px', marginRight: '1px' }}
            onClick={() => {
              void navigate('/patients/create')
            }}
            variant='outlined'
            type='button'
            data-product-tour-target='create-patient-btn'
          >
            {t('create_patient')}
          </Button>
        )}
      </div>
    </BaseAppHeader>
  )
}

AppHeader.displayName = 'CareAppHeader'
