import { t } from 'i18next'
import { useNotifications } from '../useNotifications'
import { type StartPathwayInput, useStartPathwayMutation } from './types'

interface UseStartPathwayHook {
  startPathway: (input: StartPathwayInput) => Promise<string | undefined>
}

export const useStartPathway = (): UseStartPathwayHook => {
  const { notifyError } = useNotifications()
  

  const [startPathwayMutation] = useStartPathwayMutation()

  const startPathway = async (input: StartPathwayInput): Promise<string | undefined> => {
    try {
      const { data } = await startPathwayMutation({
        variables: {
          input,
        },
        refetchQueries: ['GetPatientPathways'],
      })
      return data?.startPathway.pathway_id
    } catch (error) {
      notifyError({
        message: t('start_pathway_error'),
        error,
      })
    }
    return undefined
  }
  return {
    startPathway,
  }
}
